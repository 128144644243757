// Angular
import { Injectable, OnDestroy } from '@angular/core';

// Reactive X
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';

// Internal dependencies
import { Device } from '../../providers/traccar-client';
import { Tracker } from '../../providers/tracker-factory.service';

import { AuthService } from './auth.service';
import { RemoteConfigService } from './remote-config.service';
import { TrackerService } from '../../providers/tracker.service';


@Injectable({
  providedIn: 'root',
})
export class PassportService implements OnDestroy {

  /* CONSTANTS */

  public static readonly MANDATORY_FIELDS_BASE: string[] = [];
  public static readonly MANDATORY_FIELDS_RECOVERY: string[] = [
    ...PassportService.MANDATORY_FIELDS_BASE,
    'colour',
    'manufacturer',
    'model',
  ];

  private static readonly MANDATORY_FIELDS_CONFIG: string = 'passport_fields_mandatory';


  /* ATTRIBUTES */

  // Actions
  private reloadMandatoryFields$: Subject<void> = new Subject();

  // State
  private country$ = this.auth.user$.pipe(
    filter((user) => user !== null),
    map((user) => user.country),
    distinctUntilChanged(),
  );

  private tracker$: Observable<Tracker> = this.trackerService.getSelected();

  private device$: Observable<Device> = this.trackerService.getSelected().pipe(
    switchMap((tracker: Tracker) => merge(
      tracker.getDevice(),
      tracker.deviceChanges$,
    )),
  );

  private hasRecoveryService$: Observable<boolean> = this.tracker$.pipe(
    map((tracker: Tracker) => tracker.hasRecoveryService()),
  );

  public readonly mandatoryFields$: Observable<string[]> = this.hasRecoveryService$.pipe(
    map((hasRecoveryService: boolean) => {
      if (hasRecoveryService) {
        return PassportService.MANDATORY_FIELDS_RECOVERY;
      } else {
        return PassportService.MANDATORY_FIELDS_BASE;
      }
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  // TODO: Uncomment this once it's confirmed the backend supports this functionality
  // public readonly mandatoryFields$ = this.reloadMandatoryFields$.pipe(
  //   startWith(),
  //   switchMap(() => this.country$.pipe(take(1))),
  //   switchMap((country) =>
  //     this.remoteConfig.getConfigByName<string[]>(`${PassportService.MANDATORY_FIELDS_CONFIG}_${country}`)),
  //   distinctUntilChanged(),
  //   shareReplay(1),
  // );

  public readonly invalidMandatoryFields$ = combineLatest([
    this.device$,
    this.mandatoryFields$,
  ]).pipe(
    map(([device, mandatoryFields]) => {
      return mandatoryFields.filter((field) => {
        if (!device.attributes.passport) return true;

        const fieldValue = device.attributes.passport[field];
        return fieldValue === undefined || fieldValue === null || fieldValue?.trim() === '';
      });
    }),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    shareReplay(1),
  );


  /* LIFECYCLE */

  public constructor(
    private auth: AuthService,
    private remoteConfig: RemoteConfigService,
    private trackerService: TrackerService,
  ) { }

  public ngOnDestroy(): void {
    this.reloadMandatoryFields$.complete();
  }


  /* METHODS */

  public reloadMandatoryFields(): void {
    this.reloadMandatoryFields$.next();
  }

}
