import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AudioManagement } from '@ionic-native/audio-management/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
// import { version } from '../../package.json';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpLoggingInterceptor } from './core/services/http-interceptors/http-logging-interceptor';
import { PipesModule } from './pipe.module';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import lottiePlayer, { LottiePlayer } from 'lottie-web';

// Comment-in for Bafang demo
// import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';

export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const lottiePlayerFactory = (): LottiePlayer => {
  return lottiePlayer;
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    AngularFireRemoteConfigModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirePerformanceModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    LottieModule.forRoot({ player: lottiePlayerFactory }),
    LottieCacheModule.forRoot(),
    ReactiveFormsModule,
    ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: true }),
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    PipesModule,
  ],
  providers: [
    AppVersion,
    AudioManagement,
    BarcodeScanner,
    Camera,
    Clipboard,
    File,
    FirebaseX,
    GoogleMaps,
    HTTP,
    InAppBrowser,
    LaunchNavigator,
    NativeAudio,
    Network,
    // OpenNativeSettings,
    Device,
    // BluetoothLE,
    SocialSharing,
    StatusBar,
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoggingInterceptor, multi: true },
    // { provide: APP_VERSION, useValue: version },
    // { provide: VAPID_KEY, useValue: environment.firebaseConfig.vapidKey },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
