// Internal dependencies
import { GeofenceDTO } from './geofence-dto';

export class Geofence implements GeofenceDTO {
  /* CONSTANTS */

  // Example: 'CIRCLE (48.26270939849843 11.428278933194475, 95.1)'
  private static readonly GEOFENCE_CIRCLE_REGEX = new RegExp(/CIRCLE \((-?\d{1,2}\.\d*) (-?\d{1,3}\.\d*), (\d*.\d*)\)/);

  /* ATTRIBUTES */

  public id?: number;
  public name?: string;
  public description?: string;
  public area?: string;
  public calendarId?: number;
  public attributes?: any;

  public latitude?: number;
  public longitude?: number;
  public radius?: number;

  /* LIFECYCLE */

  public constructor(parameters: Partial<Geofence>) {
    Object.assign(this, parameters);
  }

  public static fromDTO(geofenceDTO: GeofenceDTO): Geofence {
    const regexResults = Geofence.GEOFENCE_CIRCLE_REGEX.exec(geofenceDTO.area);

    return new Geofence({
      ...geofenceDTO,
      latitude: parseFloat(regexResults[1]),
      longitude: parseFloat(regexResults[2]),
      radius: parseFloat(regexResults[3]),
    });
  }

  /* UTILITY */

  public toDTO(): GeofenceDTO {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      area: this.area,
      calendarId: this.calendarId,
      attributes: this.attributes,
    };
  }
}
