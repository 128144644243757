// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config'; // for web

// Ionic
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Platform } from '@ionic/angular';

// Reactive X
import { BehaviorSubject, defer, Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';

// Internal dependencies
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService implements OnDestroy {
  /* ATTRIBUTES */

  private imminentDestruction$: Subject<void> = new Subject();

  private isRemoteConfigReady$: Subject<boolean> = new BehaviorSubject(false);

  /* LIFECYCLE */

  public constructor(
    private analytics: AnalyticsService,
    public firebaseX: AngularFireRemoteConfig, // for web
    // private firebaseX: FirebaseX,
    private platform: Platform,
  ) {
    this.initRemoteConfig().pipe(
      takeUntil(this.imminentDestruction$),
    ).subscribe(() => this.isRemoteConfigReady$.next(true));
  }

  public ngOnDestroy(): void {
    this.imminentDestruction$.next();
    this.imminentDestruction$.complete();

    this.isRemoteConfigReady$.complete();
  }

  /* METHODS */

  private initRemoteConfig(): Observable<void> {
    return defer(() => this.platform.ready()).pipe(
      // TODO: Migrate to fetchAndActivate() method once available
      switchMap(() => this.fetchRemoteConfig()),
      switchMap(() => this.activateRemoteConfig()),
    );
  }

  private fetchRemoteConfig(): Observable<void> {
    return defer(() => this.firebaseX.fetch());
  }

  private activateRemoteConfig(): Observable<void> {
    // return defer(() => this.firebaseX.activateFetched()).pipe(
    return defer(() => this.firebaseX.activate()).pipe(
      catchError((error: any) => {
        // TODO: Check if still required with later Firebase versions (see PB-1218)
        if (error.includes('Code=8003')) {
          const message = 'Remote configuration: Firebase profile already active!';
          console.warn(message, error);
          this.analytics.captureEvent({
            extra: { error },
            level: AnalyticsService.LEVEL_WARNING,
            message,
          });
          return of(undefined);
        }

        console.error('Remote configuration: Activation failed!', error);
        this.analytics.captureException(error);

        throw error;
      }),
    );
  }

  /* ACCESSORS */

  // public getConfigByName<T = any>(name: string): Observable<T> {
  public getConfigByName(name: string): Observable<string> {
    return this.isRemoteConfigReady$.pipe(
      filter((isRemoteConfigReady) => isRemoteConfigReady),
      take(1),
      switchMap(() => this.firebaseX.getString(name)),
      // switchMap(() => this.firebaseX.getValue(name)), // native
    );
  }
}
