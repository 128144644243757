// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Internal dependencies
import { AdminBackendHttpHandler } from './admin-backend-http-handler';

@Injectable({
  providedIn: 'root',
})
export class AdminBackendClient extends HttpClient {
  /* LIFECYCLE */

  public constructor(adminBackendHttpHandler: AdminBackendHttpHandler) {
    super(adminBackendHttpHandler);
  }
}
