<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu [disabled]="hideMenu" contentId="main-content">
      <ion-header *ngIf="!profile?.isEmbedded">
        <ion-toolbar>
          <ion-item class="profile" lines="none" *ngIf="!profile" (click)="login()">
            <ion-label>
              <h2>Login</h2>
            </ion-label>
            <ion-button slot="end">
              <ion-icon name="log-in" ios="log-in-outline"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-menu-toggle autoHide="false">
            <ion-item
              button
              class="profile"
              lines="none"
              *ngIf="profile"
              routerLink="/profile"
              routerDirection="forward"
              id="menu-profile">
              <ion-avatar slot="start">
                <img [src]="profile.picture" />
              </ion-avatar>
              <ion-label>
                <h2>{{ profile.given_name || profile.nickname }}</h2>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-toolbar>
      </ion-header>

      <ion-content id="menu-content">
        <ion-searchbar
          [formControl]="searchControl"
          [placeholder]="'SEARCH' | translate"
          *ngIf="showSearch"></ion-searchbar>
        <ion-list lines="none" class="ion-margin-top" *ngIf="profile">
          <ion-radio-group id="list-trackers">
            <ng-container *ngFor="let device$ of devices | keyvalue | deviceSort">
              <ion-item
                *ngIf="(device$.value | async).name.toLowerCase().includes(searchValue)"
                lines="none"
                button
                detail="false"
                (click)="selectTracker(device$.value)">
                <!-- Can't use ion-radio as it wouldn't allow a second button to handle clicks -->
                <ion-icon
                  slot="start"
                  [color]="
                    (device$.value | async).attributes.guarded && (device$.value | async).attributes.alarm
                      ? 'danger'
                      : (device$.value | async).id === (selectedDeviceId | async)
                      ? 'secondary'
                      : (device$.value | async).attributes.guarded && !(device$.value | async).attributes.alarm
                      ? 'success'
                      : 'primarycontrast'
                  "
                  [name]="
                    (device$.value | async).id === (selectedDeviceId | async) ? 'radio-button-on' : 'radio-button-off'
                  "></ion-icon>
                <!-- Red / danger color in case of alarm, green / secondary when selected, white / primarycontrast for unselected (without alarm)  -->
                <ion-label
                  [color]="
                    (device$.value | async).attributes.guarded && (device$.value | async).attributes.alarm
                      ? 'danger'
                      : (device$.value | async).id === (selectedDeviceId | async)
                      ? 'secondary'
                      : 'primarycontrast'
                  ">
                  {{ (device$.value | async).name }}
                </ion-label>
                <ion-button
                  slot="end"
                  color="primarycontrast"
                  fill="clear"
                  *ngIf="
                    (device$.value | async).id !== (selectedDeviceId | async) &&
                    deviceBatteryStates[(device$.value | async).id]
                  "
                  class="button-settings">
                  <ion-icon slot="icon-only" [src]="deviceBatteryStates[(device$.value | async).id]"></ion-icon>
                </ion-button>
                <ion-button
                  slot="end"
                  color="secondary"
                  fill="clear"
                  routerLink="/settings"
                  routerDirection="forward"
                  *ngIf="(device$.value | async).id === (selectedDeviceId | async)"
                  class="button-settings">
                  <ion-icon slot="icon-only" name="settings-sharp" ios="settings-outline"></ion-icon>
                </ion-button>
              </ion-item>
            </ng-container>
            <ion-menu-toggle autoHide="false">
              <ion-item detail="false" routerLink="/add-bike" routerDirection="forward" id="button-add-tracker">
                <ion-icon slot="start" color="medium" name="add" ios="add-outline"></ion-icon>
                <ion-label color="medium">{{ 'ADD_TRACKER' | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-radio-group>
        </ion-list>
      </ion-content>

      <ion-list lines="none" class="nav-items">
        <ion-menu-toggle autoHide="false" *ngIf="web">
          <ion-item routerLink="/" routerDirection="forward">
            <ion-icon
              slot="start"
              name="map"
              ios="map-outline"
              [color]="router.url.startsWith('/map') ? 'secondary' : 'primarycontrast'"></ion-icon>
            <ion-label [color]="router.url.startsWith('/map') ? 'secondary' : 'primarycontrast'">
              {{ 'MAP' | translate }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle autoHide="false">
          <ion-item
            routerLink="/trips"
            routerDirection="forward"
            *ngIf="selectedDeviceId | async"
            id="button-open-trips">
            <ion-icon
              slot="start"
              name="trail-sign"
              ios="trail-sign-outline"
              [color]="router.url === '/trips' ? 'secondary' : 'primarycontrast'"></ion-icon>
            <ion-label [color]="router.url === '/trips' ? 'secondary' : 'primarycontrast'">
              {{ 'TRIPS' | translate }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle autoHide="false">
          <ion-item button id="button-open-news" *ngIf="enableNews">
            <ion-icon
              *ngIf="isNewsInitialised"
              slot="start"
              color="primarycontrast"
              name="newspaper"
              ios="newspaper-outline"></ion-icon>
            <ng-container *ngIf="isNewsInitialised">
              <ion-label *ngIf="customHelpTitle">{{ customHelpTitle }}</ion-label>
              <ion-label *ngIf="!customHelpTitle">{{ 'NEWS' | translate }}</ion-label>
            </ng-container>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle autoHide="false">
          <ion-item button *ngIf="enableHelp" (click)="openHelp()">
            <ion-icon slot="start" name="help-buoy" ios="help-buoy-outline" color="primarycontrast"></ion-icon>
            <ion-label>{{ 'HELP' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- Comment-in for Bafang demo
        <ion-menu-toggle autoHide="false">
          <ion-item detail="false" routerLink="/bafang-spike" routerDirection="forward">
            <ion-icon slot="start" name="rocket" ios="rocket-outline" color="primarycontrast"></ion-icon>
            <ion-label>Bafang Spike</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
      </ion-list>

      <ion-footer>
        <ion-item lines="none">
          <img src="assets/icon/brand-horizontal.png" width="90" />
          <small class="ion-margin-start">{{ version }}</small>
          <small class="ion-margin-start" *ngIf="deployment === 'Development'">Development</small>
        </ion-item>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
