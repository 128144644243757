import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class KeychainService {
  private static readonly TRACCAR_KEY = 'traccarKey';
  private static readonly ACCESS_GROUP = null;
  private static readonly APP_GROUP = null;

  constructor(private platform: Platform) {}

  public getTraccarPassword(): Promise<string | null> {
    if (!this.platform.is('ios')) {
      return Promise.resolve(null);
    }
    return this.getValue(KeychainService.TRACCAR_KEY);
  }

  public storeTraccarPassword(traccarPassword: string): Promise<void> {
    if (!this.platform.is('ios')) {
      return Promise.reject();
    }
    return this.setValue(KeychainService.TRACCAR_KEY, traccarPassword);
  }

  private async getValue(key: string): Promise<string | null> {
    await this.platform.ready();
    return new Promise<string | null>((resolve) => {
      (window as any).Keychain.get(
        (result: string | null) => {
          resolve(result);
        },
        (error) => {
          console.log("Couldn't get from keychain ", error);
          resolve(null);
        },
        key,
        KeychainService.APP_GROUP,
        KeychainService.ACCESS_GROUP,
      );
    });
  }

  private async setValue(key: string, value: string): Promise<void> {
    await this.platform.ready();
    return new Promise<void>((resolve, reject) => {
      (window as any).Keychain.set(
        () => resolve(),
        (error) => {
          console.log("Couldn't save to keychain ", error);
          reject();
        },
        key,
        value,
        KeychainService.APP_GROUP,
        KeychainService.ACCESS_GROUP,
      );
    });
  }
}
