// Angular
import { Injectable } from '@angular/core';

// Reactive X
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Internal dependencies
import { retryUnless } from '../utils/operators';

import { Position } from '../../../app/providers/traccar-client';
import { Geofence } from '../models/geofence/geofence';
import { GeofenceDTO } from '../models/geofence/geofence-dto';

import { TraccarClient } from './traccar/traccar-client';

@Injectable({
  providedIn: 'root',
})
export class GeofenceService {
  /* LIFECYCLE */

  public constructor(private traccar: TraccarClient) {}

  /* METHODS */

  public createGeofence(name: string, position: Position, radius: number): Observable<Geofence | null> {
    const requestBody = {
      id: -1,
      calendarId: 0,
      name,
      area: `CIRCLE (${position.latitude} ${position.longitude}, ${radius})`,
      description: '',
    };

    return this.traccar.post<GeofenceDTO | null>('geofences', requestBody).pipe(
      map((geofenceDTO) => (geofenceDTO ? Geofence.fromDTO(geofenceDTO) : null)),
      tap((geofence) => console.log('Created geofence', geofence)),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public deleteGeofence(geofenceId: number): Observable<void> {
    return this.traccar.delete<void>(`geofences/${geofenceId}`).pipe(
      tap(() => console.log('Deleted geofence with ID', geofenceId)),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }

  public assignGeofenceToDevice(geofenceId: number, deviceId: number): Observable<any> {
    const requestBody = {
      deviceId,
      geofenceId,
    };

    return this.traccar.post('permissions', requestBody).pipe(
      tap(() => console.log('Assigned geofence with ID', geofenceId, 'to device with ID', deviceId)),
      retryUnless(3, 1000, (error) => error?.status === 400),
    );
  }
}
