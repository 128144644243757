// Angular
import {Injectable, OnDestroy} from '@angular/core';

// Reactive X
import {BehaviorSubject, Subject, timer} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  mergeMap,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {WebSocketSubject} from 'rxjs/webSocket';

// He
import * as he from 'he';

// eslint-disable-next-line @typescript-eslint/no-redeclare
import {Device, Event, Position} from '../../../providers/traccar-client';
import {WebSocketConnectionState} from '../../models/web-socket/web-socket-connection-state';

import {TraccarClient} from './traccar-client';
import {TraccarSession} from './traccar-session';
import moment from "moment";
import {retry} from "../../../util/retry";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class TraccarWebSocket implements OnDestroy {
  /* ATTRIBUTES */

  public autoReconnect: boolean = true;
  public isReconnecting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public heartBeatPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ignoreNextReconnect: boolean = false;
  private imminentDestruction$: Subject<void> = new Subject();

  private webSocket$?: WebSocketSubject<any>;

  private connectionStateSource$ = new BehaviorSubject(WebSocketConnectionState.DISCONNECTED);
  public readonly connectionState$ = this.connectionStateSource$.pipe(distinctUntilChanged());

  private deviceUpdatesSource$: Subject<Device> = new Subject();
  public readonly deviceUpdates$ = this.deviceUpdatesSource$.pipe();

  private eventUpdatesSource$: Subject<Event> = new Subject();
  public readonly eventUpdates$ = this.eventUpdatesSource$.pipe();

  private positionUpdatesSource$: Subject<Position> = new Subject();
  public readonly positionUpdates$ = this.positionUpdatesSource$.pipe();

  /* LIFECYCLE */

  public constructor(
    private traccar: TraccarClient,
    private traccarSession: TraccarSession,
  ) {
    this.connect();
  }

  public ngOnDestroy(): void {
    this.imminentDestruction$.next();
    this.imminentDestruction$.complete();

    this.disconnect();
    this.connectionStateSource$.complete();

    this.deviceUpdatesSource$.complete();
    this.positionUpdatesSource$.complete();
    this.eventUpdatesSource$.complete();
  }

  /* API */

  public reconnect(): void {
    this.disconnect();
    this.connect();
  }

  public refresh() {
    this.traccarSession.reset().then(
      () => {
        console.debug('Traccar session resetted.');
        console.debug('Reconnecting WebSocket...');
        this.reconnect();
      },
      (e) => {
        this.connectionStateSource$.next(WebSocketConnectionState.DISCONNECTED);
        console.error(e);
      },
    );
  }

  /* METHODS */

  private connect(): void {
    this.connectionState$
      .pipe(take(1), takeUntil(this.imminentDestruction$))
      .subscribe((connectionState: WebSocketConnectionState) => {
        if (connectionState !== WebSocketConnectionState.DISCONNECTED) return;

        console.debug('Connecting to WebSocket...');
        this.connectionStateSource$.next(WebSocketConnectionState.CONNECTING);

        this.traccar
          .webSocket()
          .pipe(
            switchMap((webSocket$?: WebSocketSubject<any>) => {
              this.webSocket$ = webSocket$;
              console.debug('Connected to WebSocket!');
              this.connectionStateSource$.next(WebSocketConnectionState.CONNECTED);
              this.isReconnecting.next(false);
              this.heartbeat$(environment.traccarWs.interval, environment.traccarWs.timeout);

              return webSocket$.pipe(
                retry(15, 5000),
              );
            }),
            takeUntil(this.imminentDestruction$),
          )
          .subscribe({
            next: (message) => this.onMessageReceived(message),
            error: (error) => this.onError(error),
            complete: () => this.onClosed(),
          });
      });
  }

  private disconnect(): void {
    console.debug('Disconnecting from WebSocket...');
    // this.connectionStateSource$.next(WebSocketConnectionState.DISCONNECTING);

    this.ignoreNextReconnect = true;
    this.webSocket$?.complete();

    console.debug('Disconnected from WebSocket!');
    this.connectionStateSource$.next(WebSocketConnectionState.DISCONNECTED);
  }

  /* EVENT HANDLERS */

  private onMessageReceived(message: any): void {
    this.isReconnecting.next(false);
    this.heartBeatPending$.next(false);
    if (message.devices) {
      for (const device of message.devices) {
        console.debug('WebSocket received new device:', device);
        this.deviceUpdatesSource$.next({...device, name: he.decode(device.name)});
      }
    }
    if (message.events) {
      for (const event of message.events) {
        console.debug('WebSocket received new event:', event);
        this.eventUpdatesSource$.next(event);
      }
    }
    if (message.positions) {
      for (const position of message.positions) {
        console.debug('WebSocket received new position:', position);
        this.positionUpdatesSource$.next(position);
      }
    }
  }

  private onError(error: any): void {
    console.error(`WebSocket error occured: ${error}`);

    console.debug('WebSocket disconnected due to error!');
    this.connectionStateSource$.next(WebSocketConnectionState.DISCONNECTED);

    if (this.autoReconnect && !this.ignoreNextReconnect) {
      this.isReconnecting.next(true);
      console.debug('Reconnecting to WebSocket...');
      this.refresh();
    }

    this.ignoreNextReconnect = false;
  }

  private onClosed(): void {
    console.debug('WebSocket connection closed.');
    this.connectionStateSource$.next(WebSocketConnectionState.DISCONNECTED);

    if (this.autoReconnect && !this.ignoreNextReconnect) {
      console.debug('Reconnecting to WebSocket...');
      this.connect();
    }

    this.ignoreNextReconnect = false;
  }

  private heartbeat$(pingInterval: number, pongTimeout: number) {
    let start = 0;
    let slowIntervalId = 0;
    this.heartBeatPending$.next(false);

    const pong$ = this.webSocket$.pipe(
      filter(m => {
        if (m.pong) {
          const regExpQuotes = new RegExp('"', 'g');
          const pong = m.pong[0].replace(regExpQuotes, '');
          const ts = pong.replace('ping_', '').replace('"', '');
          console.log('Socket received pong', ts);
          if (Number(ts) === start) {
            console.log('Socket interval cleared!');
            clearTimeout(slowIntervalId);
            this.heartBeatPending$.next(false);
            return true;
          }
        }
      }),
      share(),
    );

    const heartbeat$ = pong$.pipe(
      startWith('{"pong": ["ping_1234"]}'),
      debounceTime(pingInterval),
      tap(() => {
        start = moment().unix();
        this.webSocket$.next('ping_' + start);
        console.log('Sending ping', 'ping_' + start);
        slowIntervalId = setTimeout(() => {
          console.log('socket connection slow');
          this.heartBeatPending$.next(true);
        }, 10000);
      }),
      mergeMap(() => timer(pongTimeout).pipe(takeUntil(pong$)))
    );

    heartbeat$.subscribe(() => {
      this.autoReconnect = true;
      this.ignoreNextReconnect = false;
      this.webSocket$.error({
        code: 3000,
        reason: 'Websocket heartbeat timed out'
      });
      console.log('socket connection timeout', environment.traccarWs.timeout);
    });
  }
}
