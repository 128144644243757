import { Injectable } from '@angular/core';
import { ILatLng } from '@ionic-native/google-maps/ngx';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Device, Event, Position } from './traccar-client';
import { GeofenceService } from '../core/services/geofence.service';
import { DeviceService } from '../core/services/device.service';
import { Trip } from '../core/models/trip';
import { DeviceGuarding } from '../core/models/device/device-guarding';

/*
 * Tracker business logic
 *
 * NOTE / TODO:
 * - device + position data could be somehow merged into a single Tracker class with flat hierarchy
 */

export interface Waypoint extends ILatLng {
  id: number;
  time: Date;
}

export interface TripDetails extends Trip {
  fetched: boolean;
  deleted: boolean;
  polyline: string;
  trips: Array<Trip>;
  waypoints: Array<Waypoint>;
}

export type Passport = Device['attributes']['passport'];

const GEOFENCE_PREFIX = 'GUARDFENCE';

/**
 * TODO: Refactor.
 */
export class Tracker {
  id: number;
  uniqueId: string;
  guardFenceId: number;

  private device: BehaviorSubject<Device>;

  private latestPosition$: Subject<Position> = new ReplaySubject(1);
  private positions: Position[] = [];

  private _batteryLevel$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  private _isCharging$: BehaviorSubject<boolean | undefined> = new BehaviorSubject(undefined);

  private event = new Subject<Event>();
  private geofence = new ReplaySubject<any>(1);
  // Note: streams complete to indicate tracker deletion

  private lastPositionSource = this.latestPosition$.pipe(
    filter((position) => !!position.latitude),
    shareReplay(1),
  );
  // TODO: not sure if it's a good thing to cache instead of querying last from stream
  private lastDevice: Device;

  // We append all new positions to our positions-array once the app has been started. Therefore we do not need to
  // reload todays positions every time we switch to a tracker.
  private todaysPositionsLoaded: boolean = false;

  // This is (only temporarily hopefully) used to dispatch device updates to services and componenents
  // which do not break from receiving those updates. Dispatching the same updates via 'device'
  // resulted in some infinite loops for some components.
  private deviceChangesSource$: BehaviorSubject<Device> = new BehaviorSubject(this.deviceData);
  public readonly deviceChanges$: Observable<Device> = this.deviceChangesSource$.pipe();

  constructor(
    public deviceData: Device,
    private deviceService: DeviceService,
    private geofenceService: GeofenceService,
    public subService: SubscriptionService,
  ) {
    this.id = deviceData.id;
    this.uniqueId = deviceData.uniqueId;
    this.device = new BehaviorSubject(deviceData);
    this.lastDevice = deviceData;

    // device attribute guardFenceId is used to detect remote changes (as this will get pushed via WS)
    this.device
      .pipe(
        filter((device) => device?.attributes?.guardType === 'geofence'),
        distinctUntilChanged((a, b) => a?.attributes?.guardFenceId === b?.attributes?.guardFenceId),
        switchMap(() => this.deviceService.fetchDeviceGeofences(this.id)),
        map((geofences) => geofences.filter((geofence) => geofence.name.startsWith(GEOFENCE_PREFIX))),
        map((geofences) => {
          const geofenceValidator = (geofence) =>
            !!this.lastDevice.attributes.guardFenceId && this.lastDevice.attributes.guardFenceId === geofence.id;

          const validGeofences = geofences.filter((geofence) => geofenceValidator(geofence));
          const invalidGeofences = geofences.filter((geofence) => !geofenceValidator(geofence));

          // Cleanup any old geofences not deleted properly
          for (const geofence of invalidGeofences) {
            this.geofenceService.deleteGeofence(geofence.id).subscribe();
          }

          return validGeofences;
        }),
      )
      .subscribe((geofences) => {
        for (const geofence of geofences) {
          this.setGeofence(geofence);
        }
      });
    // handle geofence deletion by remote app instance
    this.device
      .pipe(filter((device) => !device.attributes.guardFenceId && device.attributes.guardFenceId !== this.guardFenceId))
      .subscribe(() => this.setGeofence(null));

    this.event.pipe(filter((event) => event.type === 'deviceDeleted')).subscribe(() => this.closeStreams());
  }

  private checkIs4G(uniqueId: string): boolean {
    const regex = new RegExp('8710\\d\\d\\d\\d\\d\\d');
    return regex.test(uniqueId) ||
      Number(uniqueId) >= 4710264689 && Number(uniqueId) <= 4710265193 ||
      Number(uniqueId) >= 4710287751 && Number(uniqueId) <= 4710288750
  }

  updateDevice(device: Device) {
    this.lastDevice = device;
    this.device.next(device);
    this.deviceChangesSource$.next(device);
  }

  updatePosition(position: Position) {
    this.latestPosition$.next(position);
    this.positions = [...this.positions, position].sort((a, b) => a.id - b.id);
    this.updateBattery(position);
  }

  private updateBattery(position: Position): void {
    if (!!position.attributes.batteryLevel) {
      const estimatedBattery = this.estimateBattery(position);
      this._batteryLevel$.next(estimatedBattery);
    }
    this._isCharging$.next(position.attributes.charge);
  }

  private estimateBattery(position): number {
    // 20 days standby time with 100%
    // 2 days -> -10%
    const days = moment.duration(moment().diff(moment(position.deviceTime))).asDays();
    let timeCorrection = Math.floor(days / 2) * 10;
    timeCorrection = timeCorrection < 0 ? 0 : timeCorrection; // had cases where it must have been negative (110%)
    const estBattery = position.attributes.batteryLevel - timeCorrection;
    return estBattery < 0 ? 0 : estBattery;
  }

  updateEvent(event: Event) {
    this.event.next(event);
  }

  getDevice(): BehaviorSubject<Device> {
    return this.device;
  }

  getLastPosition(): Observable<Position> {
    return this.lastPositionSource;
  }

  setAttribute(key, value) {
    this.lastDevice.attributes[key] = value;
    this.deviceChangesSource$.next(this.lastDevice);
    return this.deviceService.updateDevice(this.lastDevice);
  }

  public getPassportAttribute<T extends keyof Passport>(key: T): Passport[T] {
    return this.lastDevice.attributes?.passport?.[key];
  }

  public setPassportAttribute<T extends keyof Passport>(key: T, value: Passport[T]): Observable<Device> {
    return this.setPassportAttributes([{ key, value }]);
  }

  public setPassportAttributes<T extends keyof Passport>(
    attributes: { key: T; value: Passport[T] }[],
  ): Observable<Device> {
    if (this.lastDevice.attributes === undefined) {
      this.lastDevice.attributes = {};
    }
    if (this.lastDevice.attributes?.passport === undefined) {
      this.lastDevice.attributes.passport = {};
    }

    for (let attribute of attributes) {
      this.lastDevice.attributes.passport[attribute.key] = attribute.value;
    }

    this.deviceChangesSource$.next(this.lastDevice);
    return this.deviceService.updateDevice(this.lastDevice);
  }

  setName(name: string) {
    this.lastDevice.name = name;
    return this.deviceService.updateDevice(this.lastDevice);
  }

  setDisabled(disabled: boolean) {
    this.lastDevice.disabled = disabled;
    return this.deviceService.updateDevice(this.lastDevice);
  }

  delete() {
    if (this.guardFenceId) {
      return this.deviceService.deleteDevice(this.uniqueId).pipe(
        switchMap(() => this.geofenceService.deleteGeofence(this.guardFenceId)),
        map(() => this.closeStreams()),
      );
    }
    return this.deviceService.deleteDevice(this.uniqueId).pipe(map(() => this.closeStreams()));
  }

  closeStreams() {
    this.device.complete();
    this.deviceChangesSource$.complete();
    this.latestPosition$.complete();
    this.event.complete();
  }

  public getTodaysPositions(fullReload: boolean = false): Observable<Position[]> {
    if (this.todaysPositionsLoaded && !fullReload) {
      return of(this.positions);
    }

    if(fullReload) {
      this.positions = [];
      console.log('Performing full positions reload...');
    }

    return this.deviceService.fetchDevicePositions(this.id, moment().startOf('day').toDate(), moment().toDate()).pipe(
      withLatestFrom(this.latestPosition$),
      map(([positions, lastPosition]) => {
        this.positions = [...positions];
        // push to last position in case we got a newer one -> fixes PB-1690 (missing position updates after resume)
        if (positions.length > 0 && lastPosition) {
          const lastTodayPosition: Position = positions[positions.length - 1];
          if (lastPosition.id < lastTodayPosition.id) {
            this.updatePosition(lastTodayPosition);
          }
        }
        return positions;
      }),
      tap(() => (this.todaysPositionsLoaded = true)),
      delay(1000), // prevent race condition with the last position observable which also triggers the polygon update
    );
  }

  getEvents(deviceId: number, start: Date, end: Date): Observable<Array<Event>> {
    return this.deviceService.fetchDeviceEvents(deviceId, start, end).pipe(map((events) => events.reverse()));
  }

  private setGeofence(geofence) {
    this.lastDevice.attributes.guardFenceId = geofence?.id;
    this.guardFenceId = geofence?.id;
    this.geofence.next(geofence);
  }

  getGeofence() {
    return this.geofence;
  }

  getTrips(deviceId: number, start: Date, end: Date): Observable<Array<Trip>> {
    return this.deviceService.fetchDeviceTrips(deviceId, start, end).pipe(map((trips) => trips.reverse()));
  }

  getTripDetails(trip: Trip): Observable<Partial<TripDetails>> {
    return this.deviceService
      .fetchDevicePositions(trip.deviceId, moment(trip.startTime).toDate(), moment(trip.endTime).toDate())
      .pipe(
        map((positions) => ({
          ...trip,
          waypoints: positions.map(
            (position: Position): Waypoint => ({
              id: position.id,
              lat: position.latitude,
              lng: position.longitude,
              time: position.fixTime,
            }),
          ),
        })),
        take(1),
      );
  }

  toggleGuarded() {
    const newDeviceGuarding = this.lastDevice.attributes.guarded ? DeviceGuarding.DISABLED : DeviceGuarding.ENABLED;
    return this.deviceService.updateDeviceGuarding(this.lastDevice.id, this.lastDevice.uniqueId, newDeviceGuarding);
  }

  //  TRACKER COMMANDS

  syncParams() {
    return this.getParam1().pipe(
      map((result) => result.split(',')),
      tap((params) => ([this.lastDevice.attributes.firmware] = params)),
      // IP -> params[3]
      // APN -> params[5]
      mergeMap(() => this.deviceService.updateDevice(this.lastDevice)),
    );
  }

  getParam1(): Observable<string> {
    console.log('-> get getParam1');
    // *HQ,4710057168,S71,085902,18#
    return this.deviceService.sendCommand(this.id, this.uniqueId, '18', '', 'S71,085902').pipe(
      // *HQ,4710057168,S71,085902,1818LK_CAR_TQ2018/04/19 18:09,id,4710057168,IP:52.58.14.232:5013,s1,m2m.tele2.com,,,S17,S06
      map((result) => result.slice(2)),
      tap((result) => console.log('<- getParam1', result)),
    );
  }

  getParam2(): Observable<string> {
    console.log('-> get getParam2');
    // *HQ,4710057168,S71,085902,19#
    return this.deviceService.sendCommand(this.id, this.uniqueId, '19', '', 'S71,085902').pipe(
      // *HQ,4710057168,S71,085902,1919ADM:,SOS1:,SOS2:,movedis:300,maxspd:0,acclt:60,accrt:30,vibtim:60
      map((result) => result.slice(2)),
      tap((result) => console.log('<- getParam2', result)),
    );
  }

  sendFormat() {
    return this.deviceService.sendCommand(this.id, this.uniqueId, '13', 'format');
  }

  sendRestart() {
    return this.deviceService.sendCommand(this.id, this.uniqueId, '212439', '', 'R1');
  }

  setUploadFrequency(frequency) {
    if (frequency < 10 || frequency > 180) {
      throw new Error(`Invalid upload frequency ${frequency}`);
    }
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '22', frequency)
      .pipe(switchMap(() => this.setAttribute('uploadFrequency', frequency)));
  }

  setAutoArm(autoArm) {
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '06', autoArm ? '000' : '111')
      .pipe(switchMap(() => this.setAttribute('autoArm', autoArm)));
  }

  setLowbatteryAlarm(lowbatteryAlarm) {
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '40', +lowbatteryAlarm)
      .pipe(switchMap(() => this.setAttribute('lowbatteryAlarm', lowbatteryAlarm)));
  }

  setGprsInStandby(gprsInStandby) {
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '42', +gprsInStandby)
      .pipe(switchMap(() => this.setAttribute('gprsInStandby', gprsInStandby)));
  }

  setGsmInStandby(gsmInStandby) {
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '43', gsmInStandby ? '0' : '1')
      .pipe(switchMap(() => this.setAttribute('gsmInStandby', gsmInStandby)));
  }

  setBluetooth(enabled) {
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '47', +enabled)
      .pipe(switchMap(() => this.setAttribute('bluetoothEnabled', enabled)));
  }

  setGps(enabled) {
    return this.deviceService
      .sendCommand(this.id, this.uniqueId, '091516', '', enabled ? 'G1' : 'G0')
      .pipe(switchMap(() => this.setAttribute('gpsDisabled', !enabled)));
  }

  /**
   * Returns the actual latest device.
   */
  public get latestDevice(): Device {
    return this.deviceChangesSource$.getValue();
  }

  public get batteryLevel(): number {
    return this._batteryLevel$.getValue();
  }

  public get isCharging(): boolean | undefined {
    return this._isCharging$.getValue();
  }

  public get is4G(): boolean {
    return this.checkIs4G(this.latestDevice.uniqueId);
  }

  public hasRecoveryService(): boolean {
    return this.subService.hasRecoveryServiceAddon(this.lastDevice.attributes);
  }

}

@Injectable({
  providedIn: 'root',
})
export class TrackerFactoryService {
  constructor(
    private deviceService: DeviceService,
    private geofenceService: GeofenceService,
    public subService: SubscriptionService,
  ) {}

  public createTracker(device: Device) {
    return new Tracker(device, this.deviceService, this.geofenceService, this.subService);
  }
}
