// Angular
import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

// Reactive X
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {
  /* METHODS */

  public intercept(request: HttpRequest<any>, handler: HttpHandler) {
    const requestTime: number = Date.now();
    let requestStatus: string;

    return handler.handle(request).pipe(
      tap({
        next: (event) => (requestStatus = event instanceof HttpResponse ? 'succeeded' : ''),
        error: () => (requestStatus = 'failed'),
      }),
      finalize(() => {
        const timeElapsed: number = Date.now() - requestTime;
        console.debug(`${request.method} "${request.urlWithParams}" ${requestStatus} in ${timeElapsed} ms.`);
      }),
    );
  }
}
