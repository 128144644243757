import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Storage } from '@ionic/storage-angular';
import * as he from 'he';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { WebSocketConnectionState } from '../core/models/web-socket/web-socket-connection-state';
import { DeviceService } from '../core/services/device.service';
import { TraccarWebSocket } from '../core/services/traccar/traccar-web-socket';
import { UserService } from '../core/services/user.service';
import { bufferUntil } from '../core/utils/operators';
import { Device } from './traccar-client';
import { Tracker, TrackerFactoryService } from './tracker-factory.service';

/*
 * 'Trackers' service - list management
 * - get a list of trackers, remove and add trackers
 * - returns Tracker objects
 * - uses traccar.service underneath
 * - create new tracker as soon as new device is seen on deviceUpdateSource -> works also when created remotely!
 *
 * Device / position updates are handled in this way:
 * - dynamically create a ReplaySubject for each device managed via a map.
 * - create a (simple) subject for devices / streams added to the map
 * - create a (simple) subject for devices / streams removed from the map
 * - maybe combine 'device' and 'position' updates in the same stream
 */

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  trackers = new Map<number, Tracker>(); // can't remove trackers from trackerSource -> still need a mapping for delete use case
  numberOfTrackers = 0;
  trackerSource = new ReplaySubject<Tracker>();

  noTrackerSource = new ReplaySubject<boolean>(1);
  selectedTrackerSource = new ReplaySubject<Tracker>(1);

  selectedTracker = this.selectedTrackerSource.pipe(distinctUntilChanged(), shareReplay(1));

  private _trackerList: BehaviorSubject<ReadonlyArray<Tracker>> = new BehaviorSubject([]);
  public readonly trackerList$: Observable<ReadonlyArray<Tracker>> = this._trackerList.asObservable();

  constructor(
    private deviceService: DeviceService,
    public network: Network,
    public storage: Storage,
    private traccarWebSocket: TraccarWebSocket,
    public trackerFactory: TrackerFactoryService,
    private userService: UserService,
  ) {
    // Fetch new device list when coming back online
    const socketBackOnlineSource = this.traccarWebSocket.connectionState$.pipe(
      pairwise(),
      filter(
        (values) =>
          values[0] === WebSocketConnectionState.DISCONNECTED && values[1] === WebSocketConnectionState.CONNECTING,
      ),
      switchMap(() => this.deviceService.refreshDevices()),
      distinctUntilChanged(),
    );
    // Combine initial loading (online) with retry handling when coming back online (or initial offline)
    const devicesSource = merge(of([]), this.network.onConnect(), socketBackOnlineSource).pipe(
      throttleTime(10000),
      switchMap(() => this.deviceService.refreshDevices()),
      tap((devices: Device[]) => {
        this.numberOfTrackers = devices.length;
        return devices.map((device) => !this.trackers.has(device.id) && this.addTracker(device));
      }),
      catchError(() => of([])),
      shareReplay(1),
    );

    // no tracker yet
    devicesSource
      .pipe(filter((devices: Device[]) => devices.length === 0))
      .subscribe(() => this.noTrackerSource.next(true));

    const selectFoundDeviceSource = combineLatest([
      from(storage.create().then(() => storage.get('selectedDeviceId'))),
      devicesSource.pipe(filter((devices: Device[]) => devices.length > 0)),
    ]).pipe(map(([storedDeviceId, devices]) => devices.find((device) => device.id === storedDeviceId)));

    merge(
      // a) Select stored trackerId from devices list
      selectFoundDeviceSource.pipe(filter((device) => !!device)),
      // b) TrackerId not in devices list -> select first from list
      selectFoundDeviceSource.pipe(
        filter((device) => !device),
        switchMap(() => devicesSource),
        map((devices) => devices[0]),
      ),
    )
      .pipe(take(1))
      .subscribe((device) => this.select(device.id));

    this.deviceService.deviceUpdates$
      .pipe(
        filter((device) => !this.trackers.has(device.id)),
        map((device) => this.addTracker(device)),
      )
      .subscribe();

    this.deviceService.deviceUpdates$
      .pipe(
        bufferUntil(devicesSource), // Wait for the 'trackers' map to be filled
        filter((device) => this.trackers.has(device.id)),
      )
      .subscribe((device) => this.trackers.get(device.id).updateDevice(device));
    this.deviceService.devicePositionUpdates$
      .pipe(
        bufferUntil(devicesSource), // Wait for the 'trackers' map to be filled
        filter((position) => this.trackers.has(position.deviceId)),
      )
      .subscribe((position) => this.trackers.get(position.deviceId).updatePosition(position));
    this.deviceService.deviceEventUpdates$
      .pipe(
        bufferUntil(devicesSource), // Wait for the 'trackers' map to be filled
        filter((event) => this.trackers.has(event.deviceId)),
      )
      .subscribe((event) => this.trackers.get(event.deviceId).updateEvent(event));
    this.deviceService.deviceAccessRevocationUpdates$
      .pipe(
        bufferUntil(devicesSource), // Wait for the 'trackers' map to be filled
        filter((deviceId) => this.trackers.has(deviceId)),
      )
      .subscribe((deviceId) => this.trackers.get(deviceId).closeStreams());
  }

  private addTracker(device: Device) {
    this.noTrackerSource.next(false);
    const tracker = this.trackerFactory.createTracker({ ...device, name: he.decode(device.name) });
    this.trackers.set(device.id, tracker);
    this.trackerSource.next(tracker);
    this.trackerList = [...this.trackers.values()];
    tracker.getDevice().subscribe({ complete: () => this.handleDelete(tracker) });
    return tracker;
  }

  public createTracker(
    device: Device,
    options: { onboardingFinished?: boolean } = { onboardingFinished: true },
  ): Observable<Tracker> {
    device.attributes = {
      // for now, we only want to migrate the passport if existing
      passport: {
        ...device.attributes?.passport,
      },
      onboardingFinished: options.onboardingFinished,
    };
    return this.deviceService.createDevice(device).pipe(
      map((createdDevice) => this.addTracker(createdDevice)),
      map((tracker) => this.select(tracker.id)),
    );
  }

  public trackerExchange(
    currentDeviceId: number,
    newUniqueId: string,
    options: { isOldHardwareRetired?: boolean } = { isOldHardwareRetired: false },
  ): Observable<any> {
    return this.deviceService.hardwareExchange(currentDeviceId, newUniqueId, options);
  }

  public changeTracker(uniqueId: string, newUniqueId: string) {
    return this.deviceService.replaceDevice(uniqueId, newUniqueId);
  }

  getNoDevicesYetUpdate() {
    return this.noTrackerSource;
  }

  getTrackers(): Subject<Tracker> {
    return this.trackerSource;
  }

  select(trackerId: number) {
    const tracker = this.trackers.get(trackerId);
    this.storage.set('selectedDeviceId', trackerId);
    this.selectedTrackerSource.next(tracker);
    return tracker;
  }

  hasTracker(trackerId: number) {
    return this.trackers.has(trackerId);
  }

  getSelected(): Observable<Tracker> {
    return this.selectedTracker;
  }

  getNumOfTrackers() {
    return this.numberOfTrackers;
  }

  private handleDelete(tracker: Tracker) {
    this.trackers.delete(tracker.id);
    this.trackerList = [...this.trackers.values()];
    if (this.trackers.size > 0) {
      this.select(this.trackers.values().next().value.id);
    } else {
      // was the last of its kind :-(
      this.noTrackerSource.next(true);
    }
  }

  estimateBattery(position) {
    // 20 days standby time with 100%
    // 2 days -> -10%
    const days = moment.duration(moment().diff(moment(position.deviceTime))).asDays();
    let timeCorrection = Math.floor(days / 2) * 10;
    timeCorrection = timeCorrection < 0 ? 0 : timeCorrection; // had cases where it must have been negative (110%)
    const estBattery = position.attributes.batteryLevel - timeCorrection;
    return estBattery < 0 ? 0 : estBattery;
  }

  generateBatteryIconPath(position) {
    const batteryLevel = this.estimateBattery(position);

    let pre = position.attributes.charge ? 'charging_' : '';
    let icon = 'unknown';

    if (batteryLevel <= 5) {
      pre = '';
      icon = 'alert';
    } else if (batteryLevel <= 25) {
      icon = '20';
    } else if (batteryLevel <= 50) {
      icon = '50';
    } else if (batteryLevel <= 70) {
      icon = '60';
    } else if (batteryLevel <= 80) {
      icon = '80';
    } else if (batteryLevel <= 100) {
      icon = 'full';
    }

    return `/assets/icon/battery/round-battery_${pre}${icon}-24px.svg`;
  }

  isSelectedOwned() {
    return combineLatest([
      this.selectedTracker.pipe(switchMap((tracker) => tracker.getDevice())),
      this.userService.user$.pipe(take(1)),
    ]).pipe(
      map(([device, user]) => {
        // workaround for "pre-sharing" b2b trackers without an ownerId -> no owner (see PB-1616)
        if (!device.attributes.ownerId && device.attributes?.B2B && user.attributes?.category !== 'B2B') {
          return false;
        }
        return !device.attributes.ownerId || device.attributes.ownerId.toString() === user.id.toString();
      }),
    );
  }

  public get trackerList(): ReadonlyArray<Tracker> {
    return this._trackerList.getValue();
  }

  public set trackerList(newTrackerList: ReadonlyArray<Tracker>) {
    this._trackerList.next(newTrackerList);
  }
}
