import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Device } from './providers/traccar-client';

@Pipe({
  name: 'noComma',
})
export class NoCommaPipe implements PipeTransform {
  transform(val: number): string {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().replace(/,/g, '');
    }

    return '';
  }
}

@Pipe({
  name: 'deviceSort',
})
export class DeviceSortPipe implements PipeTransform {
  transform(value: { key: number; value: BehaviorSubject<Device> }[]): unknown {
    if (!value) {
      return value;
    }

    const sortFn = (a, b) => {
      let val: number = 0;
      const itemA = a.value.getValue();
      const itemB = b.value.getValue();

      if (itemA.name === undefined) {
        val = -1;
      } else if (itemB.name === undefined) {
        val = 1;
      } else {
        val = itemA.name > itemB.name ? 1 : itemB.name > itemA.name ? -1 : 0;
      }

      return val;
    };

    return value.sort(sortFn);
  }
}

@NgModule({
  declarations: [NoCommaPipe, DeviceSortPipe],
  imports: [],
  exports: [NoCommaPipe, DeviceSortPipe],
})
export class PipesModule {}
