import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'map',
    pathMatch: 'full',
  },
  {
    path: 'add-bike',
    loadChildren: () => import('./pages/add-bike/add-bike.module').then((module) => module.AddBikeModule),
  },
  {
    path: 'add-biketrax',
    loadChildren: () => import('./pages/add-biketrax/add-biketrax.module').then((module) => module.AddBiketraxModule),
  },
  {
    path: 'advanced-profile',
    loadChildren: () =>
      import('./pages/advanced-profile/advanced-profile.module').then((module) => module.AdvancedProfilePageModule),
  },
  {
    path: 'advanced-settings',
    loadChildren: () =>
      import('./pages/advanced-settings/advanced-settings.module').then((module) => module.AdvancedSettingsPageModule),
  },
  {
    path: 'bluetooth',
    loadChildren: () => import('./pages/bluetooth/bluetooth.module').then((module) => module.BluetoothPageModule),
  },
  {
    path: 'configure',
    loadChildren: () => import('./pages/configure/configure.module').then((module) => module.ConfigurePageModule),
  },
  {
    path: 'creation',
    loadChildren: () => import('./pages/creation/creation.module').then((module) => module.CreationPageModule),
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/detail/detail.module').then((module) => module.DetailPageModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then((module) => module.LogoutPageModule),
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then((module) => module.MapPageModule),
  },
  {
    path: 'passport',
    loadChildren: () => import('./pages/passport/passport.module').then((module) => module.PassportPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((module) => module.ProfilePageModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then((module) => module.RegistrationModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then((module) => module.SettingsPageModule),
  },
  {
    path: 'sharing',
    loadChildren: () => import('./pages/sharing/sharing.module').then((module) => module.SharingPageModule),
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then((module) => module.SubscriptionModule),
  },
  {
    path: 'subscription/:parameters',
    loadChildren: () => import('./pages/subscription/subscription.module').then((module) => module.SubscriptionModule),
  },
  {
    path: 'theftreport',
    loadChildren: () => import('./pages/theftreport/theftreport.module').then((module) => module.TheftReportPageModule),
  },
  {
    path: 'trips',
    loadChildren: () => import('./pages/trips/trips.module').then((module) => module.TripsPageModule),
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./pages/verification/verification.module').then((module) => module.VerificationPageModule),
  },
  {
    path: '**',
    redirectTo: 'map',
    pathMatch: 'full',
  },
];

// Comment-in for Bafang demo
/*  {
  path: 'bafang-spike',
  loadChildren: () => import('./pages/bafang-spike/bafang-spike.module').then((module) => module.BafangSpikeModule),
} */

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
