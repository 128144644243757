// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

// Ionic
import { Platform } from '@ionic/angular';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

// Reactive X
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { defer, forkJoin, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Sentry
import * as Sentry from '@sentry/browser';

// Internal dependencies
import { environment } from '../../../environments/environment';

import { onComplete, onError, optimisticUpdate } from '../utils/operators';

import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

type AnalyticsEnabledUpdate = {
  analyticsEnabled: boolean;
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
  /* CONSTANTS */

  public static readonly LEVEL_CRITICAL = Sentry.Severity.Critical;
  public static readonly LEVEL_DEBUG = Sentry.Severity.Debug;
  public static readonly LEVEL_ERROR = Sentry.Severity.Error;
  public static readonly LEVEL_FATAL = Sentry.Severity.Fatal;
  public static readonly LEVEL_INFO = Sentry.Severity.Info;
  public static readonly LEVEL_LOG = Sentry.Severity.Log;
  public static readonly LEVEL_WARNING = Sentry.Severity.Warning;

  /* ATTRIBUTES */

  private imminentDestruction$: Subject<void> = new Subject();

  private authUser$ = this.auth.user$.pipe(filter((authUser) => authUser !== null));
  private traccarUser$ = this.userService.user$.pipe(filter((traccarUser) => traccarUser !== null));
  private appVersion$ = defer(() => this.appVersion.getVersionNumber()).pipe(
    map((versionNumber: string) => {
      const platformPrefix = environment.webPlatform ? 'web' : this.platform.is('ios') ? 'ios' : 'android';
      return `${platformPrefix}-${versionNumber}`;
    }),
  );

  private analyticsEnabledSource$: Subject<boolean> = new ReplaySubject(1);
  private analyticsEnabledUpdates$: Subject<AnalyticsEnabledUpdate> = new Subject();

  public readonly analyticsEnabled$ = this.analyticsEnabledSource$.pipe(
    distinctUntilChanged(),
    takeUntil(this.imminentDestruction$),
  );

  /* LIFECYCLE */

  public constructor(
    private analytics: AngularFireAnalytics,
    private appVersion: AppVersion,
    private auth: AuthService,
    private firebase: FirebaseX,
    private platform: Platform,
    private storage: StorageService,
    private userService: UserService,
  ) {
    // Initialize analytics once enabled for the first time
    this.analyticsEnabled$
      .pipe(
        filter((analyticsEnabled) => analyticsEnabled),
        take(1),
        switchMap(() => this.initAnalytics()),
        takeUntil(this.imminentDestruction$),
      )
      .subscribe();

    // Process analytics enabled updates
    this.analyticsEnabledUpdates$
      .pipe(
        optimisticUpdate(
          this.traccarUser$.pipe(map((traccarUser) => traccarUser.attributes?.sendAnalytics)),
          this.applyAnalyticsEnabledUpdate.bind(this),
          this.performAnalyticsEnabledUpdate.bind(this),
        ),
        tap((analyticsEnabled?: boolean) => {
          // If 'analyticsEnabled' is unset default to true
          if (analyticsEnabled !== null && analyticsEnabled !== undefined) return;
          this.analyticsEnabledUpdates$.next({ analyticsEnabled: true });
        }),
        filter((analyticsEnabled) => analyticsEnabled !== null && analyticsEnabled !== undefined),
        takeUntil(this.imminentDestruction$),
      )
      .subscribe(this.analyticsEnabledSource$);
  }

  public ngOnDestroy(): void {
    this.imminentDestruction$.next();
    this.imminentDestruction$.complete();

    this.analyticsEnabledSource$.complete();
    this.analyticsEnabledUpdates$.complete();
  }

  /* INITIALIZATION */

  private initAnalytics(): Observable<void> {
    return forkJoin({
      sentryRelease: this.initSentry(),
      firebase: this.initFirebase(),
      user: this.initUser()
    }).pipe(mapTo(null));
  }

  private initSentry(): Observable<void> {
    return this.appVersion$.pipe(
      switchMap((appVersion: string) => {
        Sentry.init({
          dsn: environment.sentryDsn,
          environment: environment.production ? 'production' : 'development',
          release: appVersion,
          beforeSend: (event: Sentry.Event) => {
            return this.analyticsEnabled$.toPromise().then((analyticsEnabled) => (analyticsEnabled ? event : null));
          },
        });
        console.debug('Analytics: Initialized Sentry with release identifier', appVersion);

        return this.initSentryScope();
      }),
    );
  }

  private initSentryScope(): Observable<void> {
    return forkJoin({
      authUser: this.authUser$.pipe(take(1)),
      storageDriver: this.storage.driver$.pipe(take(1)),
    }).pipe(
      map(({ authUser, storageDriver }) => {
        Sentry.configureScope((scope) => {
          // Set user details
          scope.setUser({
            id: authUser.user_id,
            email: authUser.email,
            username: authUser.name,
          });

          // Set storage driver
          scope.setTag('storageDriver', storageDriver);

          console.debug('Analytics: Initialized Sentry scope with', authUser, storageDriver);
        });
      }),
    );
  }

  private initFirebase(): Observable<void> {
    return this.authUser$.pipe(
      take(1),
      switchMap((authUser) => {
        if (environment.webPlatform) {
          return this.analytics.setUserId(authUser.user_id);
        } else {
          return this.firebase.setUserId(authUser.user_id);
        }
      }),
      tap(() => console.debug('Analytics: Initialized Firebase')),
    );
  }

  private initUser(): Observable<void> {
    return this.appVersion$.pipe(
      take(1),
      switchMap((appVersion: string) => {
        return this.userService.patchUser({
          attributes: {
            appEnvironment: this.platform.platforms().join('-'),
            appPackage: environment.packageIdentifier,
            appVersion: appVersion,
          },
        });
      }),
      map(() => undefined),
    );
  }

  /* METHODS */

  public enableAnalytics(): Observable<true> {
    return this.updateAnalyticsEnabled(true) as Observable<true>;
  }

  public disableAnalytics(): Observable<false> {
    return this.updateAnalyticsEnabled(false) as Observable<false>;
  }

  public updateAnalyticsEnabled(newAnalyticsEnabled: boolean): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      const analyticsEnabledUpdate: AnalyticsEnabledUpdate = {
        analyticsEnabled: newAnalyticsEnabled,
        onSuccess: () => {
          subscriber.next(newAnalyticsEnabled);
          subscriber.complete();
        },
        onError: (error: any) => {
          subscriber.error(error);
        },
      };

      this.analyticsEnabledUpdates$.next(analyticsEnabledUpdate);
    });
  }

  public updateScreenName(screenName: string): void {
    if (environment.webPlatform) {
      this.analytics.setCurrentScreen(screenName);
    } else {
      this.firebase.setScreenName(screenName);
    }
  }

  public captureEvent(event: Sentry.Event): string {
    return Sentry.captureEvent(event);
  }

  public captureException(exception: any): string {
    return Sentry.captureException(exception);
  }

  public captureMessage(message: string, level?: Sentry.Severity): string {
    return Sentry.captureMessage(message, level);
  }

  private applyAnalyticsEnabledUpdate(_, analyticsEnabledUpdate: AnalyticsEnabledUpdate): boolean {
    return analyticsEnabledUpdate.analyticsEnabled;
  }

  private performAnalyticsEnabledUpdate(_, analyticsEnabledUpdate: AnalyticsEnabledUpdate): Observable<boolean> {
    const userPatch$ = this.appVersion$.pipe(
      switchMap((appVersion: string) => {
        return this.userService.patchUser({
          attributes: {
            sendAnalytics: analyticsEnabledUpdate.analyticsEnabled,
            // E.g. android-phablet-cordova-mobile-hybrid
            appEnvironment: this.platform.platforms().join('-'),
            // Brand
            appPackage: environment.packageIdentifier,
            appVersion: appVersion,
          },
        });
      }),
    );

    let update$: Observable<any>;
    if (!environment.production) {
      update$ = userPatch$;
    } else {
      update$ = forkJoin([
        userPatch$,
        this.firebase.setAnalyticsCollectionEnabled(analyticsEnabledUpdate.analyticsEnabled),
        this.firebase.setPerformanceCollectionEnabled(analyticsEnabledUpdate.analyticsEnabled),
      ]);
    }

    return update$.pipe(
      onError((error) => analyticsEnabledUpdate.onError && analyticsEnabledUpdate.onError(error)),
      onComplete(() => analyticsEnabledUpdate.onSuccess && analyticsEnabledUpdate.onSuccess()),
      mapTo(analyticsEnabledUpdate.analyticsEnabled),
    );
  }
}
