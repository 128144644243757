// Angular
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Reactive X
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

// Internal dependencies
import { TraccarHttpAuthHandler } from './traccar-http-auth-handler';
import { TraccarSession } from '../traccar-session';

@Injectable({
  providedIn: 'root',
})
export class TraccarHttpSessionHandler extends HttpHandler {
  /* LIFECYCLE */

  public constructor(private traccarHttpAuthHandler: TraccarHttpAuthHandler, private traccarSession: TraccarSession) {
    super();
  }

  /* METHODS */

  /**
   * Delays all incoming requests until a session was established.
   * @param request The incoming request to handle.
   * @returns The response to the request.
   */
  public handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.traccarSession.isReady$.pipe(
      filter((isSessionReady) => isSessionReady),
      take(1),
      switchMap(() => this.traccarHttpAuthHandler.handle(request)),
    );
  }
}
