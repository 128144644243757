export const environmentOverrides = {
  webPlatform: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCXpbloXHKjNE8G7lmdsgZL1H3zTUIy2Cw',
    authDomain: 'fir-powunity.firebaseapp.com',
    databaseURL: 'https://fir-powunity.firebaseio.com',
    projectId: 'firebase-powunity',
    storageBucket: 'firebase-powunity.appspot.com',
    messagingSenderId: '437559556959',
    appId: '1:437559556959:web:0a7cf075488c28f3f8d4ea',
    measurementId: 'G-3YCHBZBGW5',
    vapidKey: 'BH59VQhnhNODDP01WtB9Tkip4pVkeOhLIr2dsG_ylR5napy68Av12h_NorYWT1Zm8kMBo_PwfeRdkbQOOnLQPgk',
  },
  sentryDsn: 'https://f7aaf4e55b214e3bb8de65438e27e15c@sentry.io/1263439',
};
