import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging'; // for web
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EMPTY } from 'rxjs';
import { catchError, delay, filter, find, mergeMap, tap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserService } from '../core/services/user.service';
import { AnalyticsService } from '../core/services/analytics.service';
import { TrackerService } from './tracker.service';

/**
 * Note: Audio alarm won't play on Chrome unless user interacted with the page:
 * https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
 */

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class PushService {
  FCMTOKEN_HOSTNAME_SUFFIXES = {}; // add custom brands here in case they use the "embedded mode", e.g. 'wuestenrot.powunity-staging.com': 'Wuestenrot',
  MAX_PUSH_TOKENS = 16;
  TOAST_DURATION = 5 * 60 * 1000; // push message is sent every 5 minutes
  ALARM_AUDIO_ID = 'alarm';
  audio: HTMLAudioElement;

  alarmToast;

  constructor(
    public analytics: AnalyticsService,
    public platform: Platform,
    public trackers: TrackerService,
    public translate: TranslateService,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public nativeAudio: NativeAudio,
    public afMessaging: AngularFireMessaging, // for web
    public deviceService: DeviceDetectorService,
    public firebaseX: FirebaseX,
    private userService: UserService,
    private device: Device
  ) {}

  init(pushToken?: string) {
    // simply store the token if called with one, otherwise init push system
    if (pushToken && pushToken !== '' && pushToken !== null) {
      let tokenAttribute;

      // special handling for embedded brands --> own token attribute in profile
      if (environment.webPlatform && Object.keys(this.FCMTOKEN_HOSTNAME_SUFFIXES).includes(window.location.hostname)) {
        tokenAttribute = `fcmTokens${this.FCMTOKEN_HOSTNAME_SUFFIXES[window.location.hostname]}`;
      }

      this.storeToken(pushToken, tokenAttribute);
    } else if (!environment.webPlatform) {
      this.initNative();
    } else {
      this.initWeb();
    }
  }

  initWeb() {
    this.checkPermissionAndTokens(true);
    if (this.deviceService.browser !== 'Safari') {
      this.afMessaging.requestToken.subscribe((token) => {
        this.storeToken(token);
      }, (err) => {
        console.warn('Unable to get permission to notify.', err);
        this.showPushDisabledAlert();
      });
      this.afMessaging.messages.subscribe((message: any) => this.handleNotification(message.data));
    }
  }

  initNative() {
    this.checkPermissionAndTokens(true);
    this.firebaseX.onTokenRefresh().subscribe(
      (token) => this.storeToken(token),
      (error) => {
        // TODO: remove error handling if never seen on Sentry
        console.error('Push onTokenRefresh', error);
        this.analytics.captureEvent({ message: error });
      },
    );
    this.firebaseX.onMessageReceived().subscribe(
      (notification) => this.handleNotification(notification),
      (error) => {
        // TODO: remove error handling if never seen on Sentry
        console.error('Push onMessageReceived', error);
        this.analytics.captureEvent({ message: error });
      },
    );
    this.firebaseX
      .createChannel({
        id: 'alarm',
        name: 'Alarm',
        sound: 'alarma',
        lightColor: '0xFFFF0000', // red LED
      })
      .then(
        () => {},
        (error) => {
          // TODO: remove error handling if never seen on Sentry
          console.error('Push createChannel', error);
          this.analytics.captureEvent({ message: error });
        },
      );
  }

  checkPermissionAndTokens(isInit = false) {
    environment.webPlatform ? this.checkTokensWeb(isInit) : this.checkPermissionAndTokensNative(isInit);
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  checkTokensWeb(isInit = false) {
    // only check when toggling armed state -> init() checks and persists the token anyway via storeToken()
    if (isInit || this.deviceService.browser === 'Safari') {
      return;
    }

    navigator.serviceWorker.getRegistration().then((registration) => {
      if (!!registration && registration.active && registration.active.state && registration.active.state === 'activated') {
        this.afMessaging.getToken.pipe(
          take(1),
        ).subscribe((token: string) => {
          this.handleGetTokenResult(token);
        });
      } else {
        console.warn('No active service worker found, not able to get firebase messaging');
      }
    });
  }

  checkPermissionAndTokensNative(isInit = false) {
    this.firebaseX.hasPermission().then(async (hasPermission) => {
      if (!hasPermission) {
        // If android version is lower than  13 (API Level 33) , grant permissions return always true
        if (this.platform.is('android') && (parseInt(this.device.version) < 13)) {
          this.showPushDisabledAlert();
          return;
        }
        // iOS -> TODO: check what happens if disabled in the settings
        this.firebaseX.grantPermission().then((res) => {
          if (!res) this.showPushDisabledAlert();
        });
      } else if (!isInit) {
        // only check when toggling armed state -> init() checks and persists the token anyway via storeToken()
        const token = await this.firebaseX.getToken();
        this.handleGetTokenResult(token);
      }
    });
  }

  async handleGetTokenResult(token: string) {
    if (!token) {
      console.error('FirebaseX did not return any token');
      this.analytics.captureEvent({
        message: 'No push token set but has permission',
        level: AnalyticsService.LEVEL_ERROR,
      });
      // await this.showPushTokenErrorAlert();
      return;
    }

    // check if token is in attributes, otherwise log and add it
    this.userService.user$
      .pipe(
        take(1),
        filter((user) => token && (!user.attributes['fcmTokens'] || !user.attributes['fcmTokens'].includes(token))),
      )
      .subscribe((user) => {
        console.error('User attributes are missing the token, adding now', token, user.attributes['fcmTokens']);
        this.analytics.captureEvent({
          message: 'Token is missing in user attributes',
          level: AnalyticsService.LEVEL_WARNING,
        });
        this.storeToken(token);
      });
  }

  async handleNotification(notification) {
    console.log('<- notification', notification);
    if (
      !notification.tap &&
      notification.notificationType &&
      notification.notificationType === 'alarm' &&
      !this.alarmToast
    ) {
      this.alarmToast = await this.toastCtrl.create({
        message: this.translate.instant('ALARM_NOTIFICATION', {
          name: notification.deviceName,
        }),
        duration: this.TOAST_DURATION,
        position: 'top',
        buttons: [
          {
            text: 'X',
            role: 'cancel',
          },
        ],
      });
      this.alarmToast.onDidDismiss().then(() => this.stopAlarm());
      this.alarmToast.present();
      this.playAlarm().catch((error) => console.warn('Failed playing audio', error));
    } else {
      // Select notification (alarm) tracker, but wait for the startup tracker selection to finish first
      this.trackers
        .getSelected()
        .pipe(
          mergeMap(() => this.trackers.getTrackers()),
          mergeMap((tracker) => tracker.getDevice()),
          find((device) => device.uniqueId === notification.uniqueId || device.uniqueId === notification.deviceId), // TODO deprecate the deviceId check later
          delay(100), // fix for Android devices where UI update was inconsistent
        )
        .subscribe((tracker) => this.trackers.select(tracker.id));
    }
  }

  storeToken(token, tokenAttribute: string = 'fcmTokens') {
    console.log('Push token', token, tokenAttribute);
    this.userService.user$
      .pipe(
        take(1),
        filter(
          (user) => token && (!user.attributes[tokenAttribute] || !user.attributes[tokenAttribute].includes(token)),
        ),
        mergeMap((user) => {
          user.attributes[tokenAttribute] = user.attributes[tokenAttribute] || [];
          if (user.attributes[tokenAttribute].length > this.MAX_PUSH_TOKENS) {
            // Traccar DB attributes column is varchar(4000) - remove oldest (first) token before adding new one to stay within limit
            user.attributes[tokenAttribute].shift();
          }
          user.attributes[tokenAttribute].push(token);

          // Store (app) locale in the Traccar User attributes to be able to localize the push message
          user.attributes.locale = this.translate.currentLang;

          return this.userService.updateUser(user);
        }),
      )
      .subscribe();
  }

  async showPushDisabledAlert() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('ERROR'),
      message: this.translate.instant(environment.webPlatform ? 'NO_PUSH_SUPPORT' : 'NO_PUSH_SUPPORT_NATIVE'),
      buttons: ['OK'],
    });
    await alert.present();
  }

  async showPushTokenErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('ERROR'),
      message: this.translate.instant('PUSH_TOKEN_ERROR', {
        emailSupport: environment.emailSupport,
      }),
      buttons: ['OK'],
    });
    await alert.present();
  }

  playAlarm() {
    if (!environment.webPlatform) {
      return this.nativeAudio
        .preloadComplex(this.ALARM_AUDIO_ID, 'assets/sound/alarma.wav', 1.0, 1, 0)
        .then(() => this.nativeAudio.loop(this.ALARM_AUDIO_ID));
    }

    this.audio = new Audio();
    this.audio.src = '/assets/sound/alarma.wav';
    this.audio.loop = true;
    this.audio.load();
    return this.audio.play();
  }

  stopAlarm() {
    if (!environment.webPlatform) {
      this.nativeAudio
        .stop(this.ALARM_AUDIO_ID)
        .then(() => this.nativeAudio.unload(this.ALARM_AUDIO_ID))
        .then(
          () => {},
          () => {
            /* err */
          },
        );
    } else if (this.audio) {
      this.audio.pause();
      this.audio.src = '';
    }
    if (this.alarmToast) {
      this.alarmToast.dismiss().catch(() => {});
      this.alarmToast = null;
    }
  }

  // eslint-disable-next-line consistent-return
  unsubscribe() {
    if (!environment.webPlatform) {
      return this.firebaseX.unregister();
    }

    if (this.deviceService.browser !== 'Safari') {
      return this.afMessaging.getToken.pipe(
        tap((token) => console.log('getToken', token)),
        mergeMap((token) => this.afMessaging.deleteToken(token)),
        tap((res) => console.log('deleteToken', res)),
        catchError((err) => {
          console.warn('afMessaging failed', err);
          return EMPTY;
        }),
      ).toPromise();
    }
  }
}
