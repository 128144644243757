// Angular
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

// Reactive X
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

// Internal dependencies
import { environment } from '../../../../environments/environment';

import { AuthService } from '../auth.service';
import { TraccarHttpAuthHandler } from './http-handlers/traccar-http-auth-handler';

import { User } from '../../../../app/providers/traccar-client';
import { CustomHttpUrlEncodingCodec } from '../../../../app/providers/traccar-client/encoder';

@Injectable({
  providedIn: 'root',
})
export class TraccarSession implements OnDestroy {
  /* CONSTANTS */

  private static readonly ENDPOINT: string = `https://${environment.traccarDomain}/api/session`;

  /* ATTRIBUTES */

  private traccarHttpClient: HttpClient = new HttpClient(this.traccarHttpAuthHandler);

  private params$ = this.auth.user$.pipe(
    filter((user) => user !== null),
    map(({ email, traccarPassword }) => {
      let formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      formParams = formParams.append('email', email);
      formParams = formParams.append('password', traccarPassword);

      return formParams;
    }),
  );

  private userSource$: Subject<User | null> = new BehaviorSubject(null);

  public readonly isReady$ = this.userSource$.pipe(
    map((sessionUser) => sessionUser !== null),
    distinctUntilChanged(),
  );

  public readonly user$ = this.userSource$.pipe(
    filter((sessionUser) => sessionUser !== null),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  /* LIFECYCLE */

  public constructor(private auth: AuthService, private traccarHttpAuthHandler: TraccarHttpAuthHandler) {
    this.init();
  }

  public ngOnDestroy(): void {
    this.userSource$.complete();
  }

  /* METHODS */

  private init(): void {
    this.params$
      .pipe(
        take(1),
        switchMap((sessionParams) => {
          return this.traccarHttpClient.post<User>(TraccarSession.ENDPOINT, sessionParams);
        }),
      )
      .subscribe((sessionUser) => {
        this.userSource$.next(sessionUser);
      });
  }

  public async reset(): Promise<void> {
    const sessionUser = await this.params$
      .pipe(
        take(1),
        switchMap((sessionParams) => {
          return this.traccarHttpClient.post<User>(TraccarSession.ENDPOINT, sessionParams);
        }),
      )
      .toPromise();
    this.userSource$.next(sessionUser);
  }

  public update(): Observable<User> {
    return this.traccarHttpClient
      .get<User>(TraccarSession.ENDPOINT)
      .pipe(tap((sessionUser) => this.userSource$.next(sessionUser)));
  }
}
