// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

// Ionic
import { Platform } from '@ionic/angular';

// Moment
import * as moment from 'moment';

// Auth0
import Auth0Cordova from '@auth0/cordova';
import { Auth0DecodedHash, Auth0UserProfile, Authentication, AuthOptions, WebAuth } from 'auth0-js';

// NGX Translate
import { TranslateService } from '@ngx-translate/core';

// Reactive X
import { BehaviorSubject, defer, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

// Internal dependencies
import { environment } from '../../../environments/environment';

import { AuthUser } from '../models/user/auth-user';
import { StorageService } from '../services/storage.service';
import { KeychainService } from './keychain.service';
import { UrlOpenerService } from './url-opener.service';

/**
 * NOTE:
 * One strategy for login is to use a very long expiration time, but require login prior to critical operations:
 * - share tracker
 * - delete tracker
 * - change password
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  /* CONSTANTS */

  private static readonly CACHE_KEY_ACCESS_TOKEN: string = 'access_token';
  private static readonly CACHE_KEY_ACCESS_TOKEN_EXPIRATION: string = 'expires_at';
  private static readonly CACHE_KEY_USER: string = 'profile';

  // NOTE: To customize 'lock' screen go to https://manage.auth0.com/#/login_page
  private static readonly AUTH0_CONFIG_BASE: AuthOptions = {
    domain: environment.auth0Config.domain,
    clientID: environment.auth0Config.clientId,
    responseType: 'token id_token',
    redirectUri: `${window.location.protocol}//${window.location.host}/#/app/callback`,
  };

  private static readonly AUTH0_CONFIG_MOBILE = {
    ...AuthService.AUTH0_CONFIG_BASE,
    clientId: environment.auth0Config.clientId,
    packageIdentifier: environment.packageIdentifier,
  };

  private readonly AUTHORIZATION_OPTIONS = {
    scope: 'openid profile email',
    ui_locales: this.translate.currentLang,
  };

  /* ATTRIBUTES */

  private auth0: WebAuth = new WebAuth(AuthService.AUTH0_CONFIG_BASE);

  private accessToken$: Subject<string | null> = new BehaviorSubject(null);

  private userSource$: Subject<AuthUser | null> = new BehaviorSubject(null);
  public readonly user$ = this.userSource$.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  /* LIFECYCLE */

  public constructor(
    private http: HttpClient,
    private cache: StorageService,
    private translate: TranslateService,
    private keychainService: KeychainService,
    private platform: Platform,
    private urlOpener: UrlOpenerService,
  ) {}

  public ngOnDestroy(): void {
    this.userSource$.complete();
  }

  /* METHODS */

  public login(): Observable<AuthUser> {
    return this.validateAuthenticationCache().pipe(
      // Restore session
      switchMap((isCacheIntact) => {
        if (isCacheIntact) return this.restoreSessionFromCache();

        return this.clearAuthenticationCache().pipe(switchMap(() => this.authenticate()));
      }),
      // Retrieve user
      switchMap(() => this.fetchAndCacheUser()),
      tap((user) => this.userSource$.next(user)),
    );
  }

  public logout(): Observable<void> {
    return this.clearAuthenticationCache().pipe(
      switchMap(async () => {
        this.userSource$.next(null);

        const auth0 = new Authentication(AuthService.AUTH0_CONFIG_BASE);
        const logoutUrl = auth0.buildLogoutUrl({
          returnTo: environment.appUrl,
          clientID: environment.auth0Config.clientId,
        });
        await this.urlOpener.logout(logoutUrl);
      }),
    );
  }

  private authenticate(): Observable<void> {
    if (environment.webPlatform) {
      return this.authenticateWeb();
    } else {
      return this.authenticateMobile();
    }
  }

  private authenticateWeb(): Observable<void> {
    return this.fetchAuth0DecodedHashWeb().pipe(
      switchMap((decodedHash: Auth0DecodedHash) => {
        const hasAccessToken = decodedHash?.accessToken !== undefined && decodedHash?.accessToken !== null;
        const hasExpiresIn = decodedHash?.expiresIn !== undefined && decodedHash?.expiresIn !== null;

        if (hasAccessToken && hasExpiresIn) {
          return this.initSession(decodedHash.accessToken, decodedHash.expiresIn);
        } else {
          this.auth0.authorize(this.AUTHORIZATION_OPTIONS);
          return of(undefined);
        }
      }),
    );
  }

  private fetchAuth0DecodedHashWeb(): Observable<Auth0DecodedHash> {
    return new Observable((subscriber) => {
      this.auth0.parseHash((error, result) => {
        if (error) {
          subscriber.error(error);
        } else {
          subscriber.next(result);
          subscriber.complete();
        }
      });
    });
  }

  private authenticateMobile(): Observable<void> {
    return this.fetchAuth0DecodedHashMobile().pipe(
      switchMap((decodedHash: Auth0DecodedHash) => {
        const hasAccessToken = decodedHash?.accessToken !== undefined && decodedHash?.accessToken !== null;
        const hasExpiresIn = decodedHash?.expiresIn !== undefined && decodedHash?.expiresIn !== null;

        if (hasAccessToken && hasExpiresIn) {
          return this.initSession(decodedHash.accessToken, decodedHash.expiresIn);
        } else {
          return throwError(() => new Error('Cannot authenticate without access token and/or expiration time!'));
        }
      }),
    );
  }

  private fetchAuth0DecodedHashMobile(): Observable<Auth0DecodedHash> {
    return new Observable((subscriber) => {
      const auth0Mobile = new Auth0Cordova(AuthService.AUTH0_CONFIG_MOBILE);
      auth0Mobile.authorize(this.AUTHORIZATION_OPTIONS, async (error, result) => {
        if (error) {
          await this.urlOpener.hideSafariView();

          subscriber.error(error);
        } else {
          subscriber.next(result);
          subscriber.complete();
        }
      });
    });
  }

  private clearAuthenticationCache(): Observable<void> {
    return forkJoin([
      this.cache.delete(AuthService.CACHE_KEY_ACCESS_TOKEN),
      this.cache.delete(AuthService.CACHE_KEY_ACCESS_TOKEN_EXPIRATION),
      this.cache.delete(AuthService.CACHE_KEY_USER),
    ]).pipe(map(() => undefined));
  }

  private validateAuthenticationCache(): Observable<boolean> {
    return forkJoin({
      accessToken: this.cache.fetch<string>(AuthService.CACHE_KEY_ACCESS_TOKEN),
      accessTokenExpiration: this.cache.fetch<number>(AuthService.CACHE_KEY_ACCESS_TOKEN_EXPIRATION),
      user: this.cache.fetch<AuthUser>(AuthService.CACHE_KEY_USER),
    }).pipe(
      map(({ accessToken, accessTokenExpiration, user }) => {
        const isAccessTokenValid = accessToken !== null;
        const isAccessTokenExpired =
          environment.webPlatform && (accessTokenExpiration === null || Date.now() >= accessTokenExpiration);
        const isEmbedded = user?.isEmbedded;

        // ignore the missing access token in case this session is embedded and the traccar PW was provided via URL params
        return (isAccessTokenValid || isEmbedded) && !isAccessTokenExpired;
      }),
    );
  }

  private initSession(accessToken: string, accessTokenExpiresIn: number): Observable<void> {
    this.accessToken$.next(accessToken);

    const accessTokenExpiration: number = Date.now() + accessTokenExpiresIn * 1000;

    return forkJoin([
      this.cache.store(AuthService.CACHE_KEY_ACCESS_TOKEN, accessToken),
      this.cache.store(AuthService.CACHE_KEY_ACCESS_TOKEN_EXPIRATION, accessTokenExpiration),
    ]).pipe(map(() => undefined));
  }

  /**
   * In the original code initializing an embedded session was sufficient to
   * count as authenticated. However, calling methods which require the
   * Auth0 access token or the 'user_id' could consequently not work.
   * Therefore this method does not give a valid authentication status anymore.
   * TODO: This should propably be discussed!
   */
  public initEmbeddedSession(
    injectedUsername: string,
    injectedPassword: string,
    embeddedPushToken?: string,
  ): Observable<void> {
    const user: AuthUser = {
      isEmbedded: true,
      email: injectedUsername,
      email_verified: true,
      traccarPassword: injectedPassword,
    };

    if (embeddedPushToken !== null && embeddedPushToken !== undefined && embeddedPushToken !== '') {
      user.embeddedPushToken = embeddedPushToken;
    }

    const expiresAt: number = moment().add(1, 'day').valueOf();

    return forkJoin([
      this.cache.store(AuthService.CACHE_KEY_USER, user),
      this.cache.store(AuthService.CACHE_KEY_ACCESS_TOKEN_EXPIRATION, expiresAt),
    ]).pipe(map(() => undefined));
  }

  private restoreSessionFromCache(): Observable<void> {
    return this.cache.fetch<string>(AuthService.CACHE_KEY_ACCESS_TOKEN).pipe(
      map((accessToken) => {
        this.accessToken$.next(accessToken);
      }),
    );
  }

  public refreshUser(): Observable<AuthUser> {
    return this.fetchAndCacheUser({ fetchFromCache: false }).pipe(tap((user) => this.userSource$.next(user)));
  }

  public resendVerificationEmail(): Observable<void> {
    return this.user$.pipe(
      filter((user) => user !== null),
      take(1),
      switchMap((user: AuthUser) => {
        return this.http.post<void>(`${environment.adminUrl}/email-verification`, { userId: user.user_id });
      }),
    );
  }

  public resetPassword(email: string): Observable<any> {
    return new Observable((subscriber) => {
      this.auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (error, result) => {
          if (error) {
            console.error('AuthService.resetPassword() failed!', error);
            subscriber.error(error);
          } else {
            console.log('AuthService.resetPassword() succeeded.', result);
            subscriber.next(result);
            subscriber.complete();
          }
        },
      );
    });
  }

  public deleteAccount(): Observable<void> {
    return this.user$.pipe(
      filter((user) => user !== null),
      take(1),
      switchMap((user: AuthUser) => {
        return this.http.delete<void>(`${environment.adminUrl}/users`, {
          headers: {
            username: user.email,
            password: user.traccarPassword,
          },
        });
      }),
    );
  }

  /* ACCESSORS */

  public isEmailVerified(): Observable<boolean> {
    return this.refreshUser().pipe(map((user) => user.email_verified));
  }

  private fetchAndCacheUser(options?: { fetchFromCache?: boolean }): Observable<AuthUser> {
    const fetchAndCacheUserFromAuth0$ = this.fetchUserFromAuth0().pipe(
      switchMap((user: AuthUser) => {
        let userToCache = { ...user };

        if (this.platform.is('ios')) {
          this.keychainService.storeTraccarPassword(user.traccarPassword);
          userToCache = { ...userToCache, traccarPassword: null };
        }

        return this.cache.store(AuthService.CACHE_KEY_USER, userToCache).pipe(mapTo(user));
      }),
    );

    if (options?.fetchFromCache === false) return fetchAndCacheUserFromAuth0$;

    const fetchCachedUserAndKeychainData$ = forkJoin({
      cachedUser: this.fetchUserFromCache(),
      keychainTraccarPassword: defer(() => this.keychainService.getTraccarPassword()),
    });

    return fetchCachedUserAndKeychainData$.pipe(
      switchMap(({ cachedUser, keychainTraccarPassword }) => {
        if (cachedUser && !this.platform.is('ios')) {
          return of(cachedUser);
        }

        if (cachedUser && keychainTraccarPassword !== null) {
          return of({ ...cachedUser, traccarPassword: keychainTraccarPassword });
        }

        return fetchAndCacheUserFromAuth0$;
      }),
    );
  }

  private fetchUserFromCache(): Observable<AuthUser | null> {
    return this.cache.fetch<AuthUser>(AuthService.CACHE_KEY_USER);
  }

  private fetchUserFromAuth0(): Observable<AuthUser> {
    return this.accessToken$.pipe(
      filter((accessToken) => accessToken !== null),
      take(1),
      switchMap(
        (accessToken: string) =>
          new Observable((subscriber) => {
            this.auth0.client.userInfo(accessToken, (error, result) => {
              if (error) {
                subscriber.error(error);
              } else {
                subscriber.next(result);
                subscriber.complete();
              }
            });
          }),
      ),
      map((auth0UserProfile: Auth0UserProfile) => ({
        ...auth0UserProfile,
        isEmbedded: false,
        user_id: auth0UserProfile.user_id || auth0UserProfile.sub,
        user_metadata: auth0UserProfile.user_metadata || {},
        traccarPassword:
          (auth0UserProfile as any).traccarPassword || auth0UserProfile['https://powunity.com/traccarPassword'],
        country: auth0UserProfile['https://powunity.com/country'],
        timezone: auth0UserProfile['https://powunity.com/timezone'],
        'https://powunity.com/traccarPassword': undefined,
        'https://powunity.com/country': undefined,
        'https://powunity.com/timezone': undefined,
      })),
    );
  }
}
