// default environment settings for building a powunity, mobile, PROD app

export const environmentDefaults = {
  branding: 'powunity',
  brandName: 'PowUnity',
  production: true,
  webPlatform: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCXpbloXHKjNE8G7lmdsgZL1H3zTUIy2Cw',
    authDomain: 'fir-powunity.firebaseapp.com',
    databaseURL: 'https://fir-powunity.firebaseio.com',
    projectId: 'firebase-powunity',
    storageBucket: 'firebase-powunity.appspot.com',
    messagingSenderId: '437559556959',
    appId: '1:437559556959:web:0a7cf075488c28f3f8d4ea',
    measurementId: 'G-3YCHBZBGW5',
    vapidKey: 'BH59VQhnhNODDP01WtB9Tkip4pVkeOhLIr2dsG_ylR5napy68Av12h_NorYWT1Zm8kMBo_PwfeRdkbQOOnLQPgk',
  },
  mapsApiKey: 'AIzaSyD05gd7KxMlIRCR_rEYt9aRsIEB5pxT48M',
  auth0Config: {
    clientId: 'Q48RAePhynOGx2Vnhf5VFzUtPtf2eAiG',
    domain: 'powunity.eu.auth0.com',
  },
  traccarWs: {
    interval: 20000,
    timeout: 30000,
  },
  packageIdentifier: 'com.powunity.app',
  traccarDomain: 'traccar.powunity.com',
  adminUrl: 'https://admin.powunity.com/api',
  appUrl: 'https://go.powunity.com/app',
  sentryDsn: 'https://f7aaf4e55b214e3bb8de65438e27e15c@sentry.io/1263439',
  paymentUrl: 'https://payment.powunity.com',
  shopUrl: 'http://bit.ly/PowUnity-Shop',
  chargebeeSite: 'powunity',
  imageUploadUrl: 'https://api.cloudinary.com/v1_1/powunity/image/upload',
  imageUploadOptions: {
    params: {
      upload_preset_passport: 'production_passport',
      upload_preset_maintenance: 'production_maintenance',
    },
  },
  beamerId: 'nTvjexsg19534',
  enableNews: true,
  enableHelp: true,
  customHelpUrl: '',
  customHelpTitle: '',
  customPassportTitle: '',
  emailSupport: 'support@powunity.com',
  splashScreenDelayMs: 0,
  branchioLinkDomain: 'go.powunity.com',
  branchioToken: 'key_live_girT22GfkUhsXpJeDVU92fekErnuIR0v',
};
