// Angular
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Reactive X
import { Observable } from 'rxjs';

// Internal dependencies
import { environment } from '../../../../../environments/environment';

import { TraccarHttpSessionHandler } from './traccar-http-session-handler';

@Injectable({
  providedIn: 'root',
})
export class TraccarHttpHandler extends HttpHandler {
  /* CONSTANTS */

  private static readonly API_PATH: string = `https://${environment.traccarDomain}/api`;

  /* LIFECYCLE */

  public constructor(private traccarHttpSessionHandler: TraccarHttpSessionHandler) {
    super();
  }

  /* METHODS */

  /**
   * Prepends the Traccar API path to all requests URLs.
   * @param request The incoming request to handle.
   * @returns The response to the request.
   */
  public handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      url: `${TraccarHttpHandler.API_PATH}/${request.url}`,
    });

    return this.traccarHttpSessionHandler.handle(modifiedRequest);
  }
}
