/**
 * Environment config used by the app.
 *
 * 1. Imports default values
 * 3. Imports overrides for target environment (e.g. brand)
 */

import { environmentDefaults } from './environment-defaults';
import { environmentOverrides } from './environment-overrides';

export const environment = {
  ...environmentDefaults,
  ...environmentOverrides,
};
