// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Reactive X
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

// Internal dependencies
import { environment } from '../../../../environments/environment';

import { TraccarHttpHandler } from './http-handlers/traccar-http-handler';
import { TraccarSession } from './traccar-session';

/**
 * This service serves as a client for the Traccar backend.
 * It automatically establishes a session, authenticates any requests
 * or WebSocket connections and points them towards the correct endpoint.
 */
@Injectable({
  providedIn: 'root',
})
export class TraccarClient extends HttpClient {
  /* CONSTANTS */

  private static readonly WEB_SOCKET_PATH: string = `wss://${environment.traccarDomain}/api/socket`;

  /* LIFECYCLE */

  public constructor(traccarHttpHandler: TraccarHttpHandler, private traccarSession: TraccarSession) {
    super(traccarHttpHandler);
  }

  /* METHODS */

  public webSocket<T>(): Observable<WebSocketSubject<T>> {
    return this.traccarSession.isReady$.pipe(
      filter((isSessionReady) => isSessionReady),
      take(1),
      map(() => webSocket<T>(TraccarClient.WEB_SOCKET_PATH)),
    );
  }
}
