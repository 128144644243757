// Angular
import { Injectable, OnDestroy } from '@angular/core';

// Reactive X
import { Observable, of, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Internal dependencies
import { onComplete, onError, optimisticUpdate, retryUnless } from '../utils/operators';
import { Patch } from '../utils/types';

import { User } from '../../../app/providers/traccar-client';

import { TraccarClient } from './traccar/traccar-client';
import { TraccarSession } from './traccar/traccar-session';
import { AdminBackendClient } from './admin-backend/admin-backend-client';
import { Invitation } from '../models/invitation/pending-invitation';

type UserUpdate = {
  user: User;
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  /* CONSTANTS */

  public static readonly DEFAULT_USER_TOKEN_LENGTH: number = 32;
  public static readonly DEFAULT_USER_TOKEN_SYMBOLS: string =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  /* ATTRIBUTES */

  private imminentDestruction$: Subject<void> = new Subject();

  private userSource$: Subject<User> = new ReplaySubject(1);
  private userUpdates$: Subject<UserUpdate> = new Subject();

  public readonly user$: Observable<User> = this.userSource$.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    takeUntil(this.imminentDestruction$),
  );

  /* LIFECYCLE */

  public constructor(private traccar: TraccarClient, private traccarSession: TraccarSession, private adminBackend: AdminBackendClient) {
    this.userUpdates$
      .pipe(
        optimisticUpdate(
          this.traccarSession.user$,
          (_, userUpdate: UserUpdate) => userUpdate.user,
          (_, userUpdate: UserUpdate) => {
            return this.traccar.put<User>(`users/${encodeURIComponent(userUpdate.user.id)}`, userUpdate.user).pipe(
              retryUnless(3, 1000, (error) => error?.status === 400),
              onError((error: any) => userUpdate.onError && userUpdate.onError(error)),
              onComplete(() => userUpdate.onSuccess && userUpdate.onSuccess()),
            );
          },
        ),
        takeUntil(this.imminentDestruction$),
      )
      .subscribe(this.userSource$);
  }

  public ngOnDestroy(): void {
    this.imminentDestruction$.next();
    this.imminentDestruction$.complete();

    this.userSource$.complete();
    this.userUpdates$.complete();
  }

  /* METHODS */

  public updateUser(newUser: User): Observable<User> {
    return new Observable((subscriber: Subscriber<User>) => {
      const userUpdate: UserUpdate = {
        user: newUser,
        onSuccess: () => {
          subscriber.next(newUser);
          subscriber.complete();
        },
        onError: (error: any) => {
          subscriber.error(error);
        },
      };

      this.userUpdates$.next(userUpdate);
    });
  }

  public patchUser(userPatch: Patch<User>): Observable<User> {
    return this.user$.pipe(
      take(1),
      switchMap((currentUser: User) => {
        // Deep patch current user
        const patchedUser: User = {
          ...currentUser,
          ...userPatch,
          attributes: {
            ...currentUser.attributes,
            ...userPatch.attributes,
          },
        };

        // Check for changes
        if (JSON.stringify(patchedUser) === JSON.stringify(currentUser)) {
          return of(currentUser);
        } else {
          return this.updateUser(patchedUser);
        }
      }),
    );
  }

  /**
   * NOTE: The returned user simply serves convenience (and compatibilty with existing code).
   * Its usage might indicate imperative programming. Whenever possible use the provided 'user$' stream instead.
   */
  public refreshUser(): Observable<User> {
    return this.traccarSession.update();
  }

  public refreshUserToken(tokenLength?: number, tokenSymbols?: string): Observable<User> {
    return this.user$.pipe(
      take(1),
      switchMap((user) => this.refreshTokenForUser(user, tokenLength, tokenSymbols)),
    );
  }

  public refreshUserTokenIfMissing(tokenLength?: number, tokenSymbols?: string): Observable<User> {
    return this.user$.pipe(
      take(1),
      filter((user) => user.token === null || user.token === undefined),
      switchMap((user) => this.refreshTokenForUser(user, tokenLength, tokenSymbols)),
    );
  }

  private refreshTokenForUser(
    user: User,
    tokenLength: number = UserService.DEFAULT_USER_TOKEN_LENGTH,
    tokenSymbols: string = UserService.DEFAULT_USER_TOKEN_SYMBOLS,
  ): Observable<User> {
    // Code as in Traccar Web but with proper randomisation instead of Math.random();
    const randomValues = new Uint32Array(tokenLength);
    window.crypto.getRandomValues(randomValues);

    let newToken: string = '';
    for (let i = 0; i < randomValues.length; i += 1) {
      newToken += tokenSymbols.charAt(randomValues[i] % tokenSymbols.length);
    }

    const newUser: User = { ...user, token: newToken };

    return this.updateUser(newUser);
  }

  public getInvitations(): Observable<Invitation[]>{
    return this.adminBackend.get<any>('invitations/pending').pipe(
      map((response: Invitation[])=>{
        return response.map((invitation: any) => ({
          invitationExpiresAt: new Date(invitation.invitationExpiresAt),
          sharerEmail: invitation.sharerEmail,
        }));
      })

    );
  }
}
