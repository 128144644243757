// Angular
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Reactive X
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

// Internal dependencies
import { AuthService } from '../../auth.service';

@Injectable({
  providedIn: 'root',
})
export class TraccarHttpAuthHandler extends HttpHandler {
  /* ATTRIBUTES */

  private authToken$ = this.auth.user$.pipe(
    filter((user) => user !== null),
    map(({ email, traccarPassword }) => btoa(`${email}:${traccarPassword}`)),
  );

  /* LIFECYCLE */

  public constructor(private httpHandler: HttpHandler, private auth: AuthService) {
    super();
  }

  /* METHODS */

  /**
   * Injects the authentication header into all requests.
   * @param request The incoming request to handle.
   * @returns The response to the request.
   */
  public handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.authToken$.pipe(
      take(1),
      switchMap((authToken) => {
        const modifiedRequest = request.clone({
          withCredentials: true,
          setHeaders: {
            Accept: 'application/json',
            Authorization: `Basic ${authToken}`,
            'Disable-Payload-Encoding': 'true',
          },
        });
        return this.httpHandler.handle(modifiedRequest);
      }),
    );
  }
}
