import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { SafariViewController } from '@ionic-native/safari-view-controller';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlOpenerService {
  constructor(private translate: TranslateService) {}

  public openPrivacyPolicy() {
    let privacyPolicyUrl = 'https://powunity.com/en/privacy-policy-app';

    if (this.translate.currentLang.toUpperCase().includes('DE')) {
      privacyPolicyUrl = 'https://powunity.com/datenschutzrichtlinien-app';
    }

    this.openUrl(privacyPolicyUrl);
  }

  public openHelp() {
    let helpUrl;

    if (environment.customHelpUrl && environment.customHelpUrl !== '') {
      // handle co-branded custom url
      helpUrl = environment.customHelpUrl;
    } else {
      helpUrl = 'https://service.powunity.com/knowsystem';

      if (this.translate.currentLang.toUpperCase().includes('DE')) {
        helpUrl = 'https://service.powunity.com/de/knowsystem';
      }
    }

    this.openUrl(helpUrl);
  }

  public async logout(logoutUrl: string): Promise<void> {
    const isAvailable: boolean = await SafariViewController.isAvailable();
    if (isAvailable) {
        await SafariViewController.show({
        url: logoutUrl,
      })
        .pipe(
          filter((result) => result.event === 'loaded'),
          tap(async () => {
            this.hideSafariView();
          }),
          take(1),
        )
        .toPromise();
    } else {
      window.open(logoutUrl, environment.webPlatform ? '_self' : '_system');
    }
  }

  public async hideSafariView(): Promise<void> {
    const isAvailable: boolean = await SafariViewController.isAvailable();
    if (isAvailable) {
      SafariViewController.hide();
    }
  }

  public async openUrl(url: string): Promise<void> {
    const isAvailable: boolean = await SafariViewController.isAvailable();
    if (isAvailable) {
        SafariViewController.show({
        url: url,
      }).subscribe({
        error: (error: any) => console.error(error),
      });
    } else {
      window.open(url, environment.webPlatform ? '_self' : '_system');
    }
  }
}
