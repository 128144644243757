// Angular
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Reactive X
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

// Internal dependencies
import { environment } from '../../../../environments/environment';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminBackendHttpHandler extends HttpHandler {
  /* CONSTANTS */

  private static readonly API_PATH: string = environment.adminUrl;

  /* LIFECYCLE */

  public constructor(private httpHandler: HttpHandler, private auth: AuthService) {
    super();
  }

  /* METHODS */

  /**
   * Prepends the Admin server API path to all requests URLs
   * and injects the required authentication headers.
   * @param request The incoming request to handle.
   * @returns The response to the request.
   */
  public handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.auth.user$.pipe(
      filter((user) => user !== null),
      take(1),
      switchMap((userProfile) => {
        const modifiedRequest = request.clone({
          url: `${AdminBackendHttpHandler.API_PATH}/${request.url}`,
          setHeaders: {
            username: userProfile.email,
            password: userProfile.traccarPassword,
          },
        });

        return this.httpHandler.handle(modifiedRequest);
      }),
    );
  }
}
