import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AnalyticsService } from '../core/services/analytics.service';
import { AuthService } from '../core/services/auth.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { retryUnless } from '../core/utils/operators';
import { Device } from './traccar-client';
import { UrlOpenerService } from '../core/services/url-opener.service';
import { Constants } from '../core/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  headersSource = this.auth.user$.pipe(
    filter((user) => user !== null),
    tap((profile) => (this.country = profile?.country)),
    map((profile) => ({
      headers: {
        username: profile.email,
        password: profile.traccarPassword,
      },
    })),
  );

  private country$: Observable<string | undefined> = this.auth.user$.pipe(
    filter((user) => user !== null),
    map((user) => user.country ?? undefined),
    distinctUntilChanged(),
    shareReplay(1),
  );

  cbInstance;
  country;
  showTrialEndAlert = true;

  constructor(
    public analytics: AnalyticsService,
    public auth: AuthService,
    public http: HttpClient,
    public rendererFactory: RendererFactory2,
    public translate: TranslateService,
    public alertCtrl: AlertController,
    public router: Router,
    private userService: UserService,
    private urlOpener: UrlOpenerService,
  ) {
    this.addJsToElement('https://js.chargebee.com/v2/chargebee.js').onload = () => {
      this.cbInstance = (window as any).Chargebee.init({
        site: environment.chargebeeSite,
        // no browser titlebar, close buttons will work (!), back button closes the whole app
        iframeOnly: !environment.webPlatform,
      });
      (window as any).Chargebee.registerAgain();
    };
  }

  getPlans(uniqueId: string): Observable<any> {
    return this.country$.pipe(
      filter((country: string | undefined) => country !== undefined),
      take(1),
      switchMap((country: string) => {
        const currency: string = country === 'PL' ? 'PLN' : 'EUR';
        return this.http.get<any>(`${environment.paymentUrl}/api/plans/${uniqueId}/${currency}`, {
          headers: { country },
        });
      }),
    );
  }

  getCancelledSubscription(uniqueId: string): Observable<any> {
    return this.userService.refreshUser().pipe(
      switchMap((user) => {
        // When the app starts (see 'ngOnInit' in the app component), missing customer
        // identifiers are patched automatically if possible.
        // Thus we can assume that if the customer id is still missing,
        // the user has never made a purchase using Chargebee and cannot have
        // any cancelled subscriptions.
        if (user.attributes?.cbCustomerId === undefined) return of([]);

        return this.http.get<any>(`${environment.paymentUrl}/api/subscriptions/${uniqueId}/cancelled`, {
          params: {
            customerId: user.attributes.cbCustomerId,
          },
        });
      }),
    );
  }

  getSubscription(uniqueId: string) {
    return this.headersSource.pipe(
      take(1),
      mergeMap((headers) => this.http.get(`${environment.adminUrl}/subscriptions/${uniqueId}`, headers)),
    );
  }

  reactivateSubscription(uniqueId: string, planId: string) {
    return this.userService.refreshUser().pipe(
      switchMap((user) =>
        this.http.post(
          `${environment.paymentUrl}/api/subscriptions/${uniqueId}/reactivate`,
          {},
          {
            params: {
              customerId: user.attributes.cbCustomerId,
              planId,
            },
          },
        ),
      ),
    );
  }

  // returns a chargebee subscription
  addPayment(planId, device, addons?: string): Observable<any> {
    // fetch latest user data (for latest cbCustomerId) as it's not yet updated via websocket after first subscription
    return this.userService
      .refreshUser()
      .pipe(switchMap((user) => {
        return this.getHostedPage(user.email, user.attributes.cbCustomerId, planId, device, addons)
          .pipe(
            switchMap((hostedPage) => {
              if (hostedPage?.chargebeeRedirectRequired ?? true) {
                return this.openCheckout(hostedPage);
              }
              return new Observable((observer) => {
                observer.next(hostedPage?.subscription); +
                  observer.complete();
              });
            })
          )
      }));
  }

  private getHostedPage(customerEmail, customerId, planId, device, addons?: string): Observable<any> {
    return this.http
      .post(
        `${environment.paymentUrl}/api/generate_checkout_new_url`,
        this.getFormUrlEncoded({
          planId,
          deviceId: device.uniqueId, // TODO switch to uniqueId once checkout service is switched to uniqueId
          country: this.country?.toUpperCase() || undefined,
          customerEmail,
          customerId,
          addons,
          trialEnd: moment(device.attributes.trialEnd).isBefore(moment())
            ? undefined
            : moment(device.attributes.trialEnd).toISOString(),
        }),
        { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) },
      );
  }

  getUserAttributes(): Observable<any> {
    return this.userService.user$.pipe(
      take(1),
      map((user) => user.attributes),
    );
  }

  openCheckout(hostedPage: any) {
    return new Observable((observer) => {
      this.cbInstance.openCheckout({
        // If you want to use paypal, go cardless and plaid, pass embed parameter as false
        hostedPage: () => new Promise((resolve) => resolve(hostedPage)),
        loaded: () => { },
        close: () => this.cbInstance.logout(),
        success: (hostedPageId) => {
          this.http
            .get(`${environment.paymentUrl}/api/hosted_page/${hostedPageId}`)
            .pipe(retryUnless(3, 1000, (error) => error?.status === 400))
            .subscribe((hostedPage: any) => {
              if (hostedPage.state === 'succeeded') {
                observer.next(hostedPage.content.subscription);
              } else {
                observer.error(`Invalid hostedPage state returned: ${hostedPage.state}`);
              }
              observer.complete();
            });
        },
        step: () => {},
      });
    });
  }

  openPortal() {
    this.userService.user$.pipe(take(1)).subscribe((user) => {
      if (!environment.webPlatform) {
        // Open in external browser due to invoice download not working otherwise (see PB-1152)
        this.http
          .post(
            `${environment.paymentUrl}/api/generate_portal_session`,
            this.getFormUrlEncoded({ customerId: user.attributes.cbCustomerId }),
            { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) },
          )
          .subscribe(
            (portalSession: any) => this.urlOpener.openUrl(portalSession.access_url),
            (err) => this.handleOpenPortalError(err),
          );
      } else {
        try {
          this.cbInstance.setPortalSession(() =>
            this.http
              .post(
                `${environment.paymentUrl}/api/generate_portal_session`,
                this.getFormUrlEncoded({ customerId: user.attributes.cbCustomerId }),
                { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) },
              )
              .toPromise(),
          );
          const cbPortal = this.cbInstance.createChargebeePortal();
          cbPortal.open({
            close: () => { },
          });
        } catch (err) {
          this.handleOpenPortalError(err);
        }
      }
    });
  }

  getFormUrlEncoded(toConvert) {
    const formBody = [];

    Object.entries(toConvert).forEach(([key, value]: [string, any]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);

      if (encodedValue === 'undefined') {
        // do not include keys with "undefined" as value
        return;
      }

      formBody.push(`${encodedKey}=${encodedValue}`);
    });

    return formBody.join('&');
  }

  // NOTE: cache renderer if called multiple times (https://stackoverflow.com/a/47925259)
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = src;
    this.rendererFactory.createRenderer(null, null).appendChild(document.body, script);
    return script;
  }

  async nonB2bSubscriptionCases(attributes) {
    // show alert in case trial is about to expire in next 5 days, but not already expired
    if (
      this.showTrialEndAlert &&
      !attributes.subscription &&
      attributes.trialEnd &&
      moment().isBetween(moment(attributes.trialEnd).subtract(5, 'days'), moment(attributes.trialEnd))
    ) {
      this.showTrialEndAlert = false;
      const expiryDate = moment(attributes.trialEnd).format(moment.localeData().longDateFormat('L'));
      await this.showTrailEndSubscriptionDialog(expiryDate);
    }

    // Go to subscription page if no subscription is active
    if (!this.isSubscriptionSufficient(attributes)) {
      this.router.navigateByUrl('/subscription');
    }

    // show alert in case the subscription is cancelled due to missing card data
    if (attributes.subscription?.status === 'cancelled' && attributes.subscription?.cancel_reason) {
      await this.showCancelledSubscriptionDialog();
    }
  }

  async showTrailEndSubscriptionDialog(expiryDate: string) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('TRIAL_ENDING_TITLE'),
      message: this.translate.instant('TRIAL_ENDING_MESSAGE', { date: expiryDate }),
      buttons: [
        {
          text: this.translate.instant('CANCEL'),
          cssClass: 'secondary',
          handler: () => { },
        },
        {
          text: this.translate.instant('TRIAL_ENDING_GOTOSUBSCRIPTIONS'),
          handler: () => this.router.navigateByUrl('/subscription'),
        },
      ],
    });
    await alert.present();
  }

  async showCancelledSubscriptionDialog() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('SUBSCRIPTION.TITLE'),
      message: this.translate.instant('SUBSCRIPTION_MISSING_PAYMENTDATA'),
      buttons: [
        {
          text: this.translate.instant('CANCEL'),
          cssClass: 'secondary',
          handler: () => { },
        },
        {
          text: 'OK',
          handler: () => this.openPortal(),
        },
      ],
    });
    await alert.present();
  }

  async handleOpenPortalError(err) {
    console.log('Error opening the portal', err);

    const alert = await this.alertCtrl.create({
      header: this.translate.instant('SUBSCRIPTION.TITLE'),
      message: this.translate.instant('SUBSCRIPTION_OPENPORTAL_ERROR', { emailSupport: environment.emailSupport }),
      buttons: [
        {
          text: 'OK',
          handler: () => { },
        },
      ],
    });
    await alert.present();

    this.analytics.captureException(err);
  }

  public isSubscriptionSufficient(attributes: Device['attributes']): boolean {
    if (attributes.B2B === true) return true;

    const noOrExpiredTrial: boolean = !attributes.trialEnd || moment(attributes.trialEnd).isBefore(moment(), 'hour');
    const noSubscription: boolean = !attributes.subscription;
    const cancelledSubscription: boolean = attributes.subscription?.status === 'cancelled';
    const noCancelReason: boolean = !attributes.subscription?.cancel_reason;

    const noneActive: boolean = noOrExpiredTrial && (noSubscription || (cancelledSubscription && noCancelReason));

    return !noneActive;
  }

  public hasRecoveryServiceAddon(attributes: Device['attributes']): boolean {
    return attributes.subscription?.addons?.find((addon: any) => {
      return Constants.RECOVERY_SERVICE_ADDON_IDS.includes(addon.id);
    }) !== undefined;
  }
}

