// Angular
import { Injectable } from '@angular/core';

// Ionic
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Storage } from '@ionic/storage-angular';

// Reactive X
import { BehaviorSubject, defer, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /* ATTRIBUTES */

  private storage$: Subject<Storage | null> = new BehaviorSubject(null);

  public readonly driver$ = this.storage$.pipe(
    filter((storage) => storage !== null),
    map((storage) => storage.driver),
  );

  /* LIFECYCLE */

  public constructor(private storage: Storage) {
    this.initialize().subscribe();
  }

  private initialize(): Observable<void> {
    return defer(() => this.storage.create()).pipe(map((storage) => this.storage$.next(storage)));
  }

  /* ACCESSORS */

  public fetch<T>(key: string): Observable<T | null> {
    return this.storage$.pipe(
      filter((storage) => storage !== null),
      switchMap((storage) => defer(() => storage.get(key))),
      map((value?: any) => {
        if (value === null || value === undefined) return null;
        return value;
      }),
      take(1),
    );
  }

  public store(key: string, value: any): Observable<void> {
    return this.storage$.pipe(
      filter((storage) => storage !== null),
      switchMap((storage) => defer(() => storage.set(key, value))),
      take(1),
    );
  }

  public delete(key: string): Observable<void> {
    return this.storage$.pipe(
      filter((storage) => storage !== null),
      switchMap((storage) => defer(() => storage.remove(key))),
      take(1),
    );
  }
}
